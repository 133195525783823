import React from 'react'

import Navbar from 'components/Navbar/Navbar'

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen w-full flex p-6 gap-4 items-center justify-center text-softBlack'>
        <div className='w-full max-w-2xl font-bold text-2xl text-softBlack underline'>
          Über uns
        </div>
        <div className='bg-white rounded-lg max-w-2xl p-4'>
          <p>
            An vielen Wochenenden haben wir in unserer Freizeit dieses Projekt
            erstellt, damit die Schnupper- & Lehrstellensuche für (v.a.
            leistungsschwächere) Schülerinnen und Schüler deutlich vereinfacht
            wird.
          </p>
          <br />
          Das Hauptziel ist, dass alle Schülerinnen und Schüler ohne grosse
          Hilfe und mit wenigen Klicks einen Lebenslauf und ein
          Motivationsschreiben erstellen.
          <p>&nbsp;</p>
          <span className='font-bold'>Wer sind wir?</span>
          <br />
          Wir sind ein kreatives Team, bestehend aus zwei Lehrpersonen
          (Klassenlehrperson Niveau C & IF-Lehrperson) und einem tollen
          Informatiker, der uns dieses unglaubliche Tool programmiert und
          ermöglicht hat.
          <p>&nbsp;</p>
          <span className='font-bold'>
            Wichtig: Es werden auf unserer Webseite keine Daten gespeichert!
          </span>
          <br />
          Verbesserungsvorschläge & Anregungen bitte unbedingt an{' '}
          <a
            href='mailto:info@lehrstell.ch'
            className='text-blue-500 underline font-medium'
          >
            info@lehrstell.ch
          </a>{' '}
          schicken!
        </div>
      </div>
    </>
  )
}

export default AboutUs
