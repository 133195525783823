import React, { useState, useEffect } from 'react'

import EmptyPage from 'components/EmptyPage'
import EditSkills from 'components/EditSkills'
import AutoComplete from 'components/AutoComplete'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import { languages, proficiency } from 'components/Student/EditLanguage/data'

import DeleteIcon from 'assets/icons/delete'
import { updateLanguages } from './updateLanguages'
import { translation } from 'utils/germanTranslation'
import useGetAllLanguages from 'hooks/useGetAllLanguages'
import useBackButton from 'hooks/useGetBack'

const VerifiedEditLanguage = ({ id, navigate }) => {
  const { loading: languagesLoading, data } = useGetAllLanguages(id)
  const [languageOptions, setLanguageOptions] = useState(languages)
  const [proficiencyOptions, setProficiencyOptions] = useState(proficiency)
  const [value, setValue] = useState([])
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const onBackButtonEvent = useBackButton(value)

  const handleChange = (newValue, index, field) => {
    const temp = [...value].map((object, i) =>
      i === index ? { ...object, [field]: newValue || '' } : object
    )
    setValue(temp)
  }

  const handleDelete = (index) =>
    setValue((prev) => prev.filter((_, i) => i !== index))

  useEffect(() => {
    if (data) setValue(data)
  }, [data])

  return (
    <EditSkills
      pageTitle={translation.languages}
      onUpdateClick={() =>
        updateLanguages(
          value,
          setLoading,
          id,
          setErrors,
          navigate,
          onBackButtonEvent
        )
      }
      loading={languagesLoading}
      disabled={loading || languagesLoading}
    >
      <SimpleEditWrapper
        value={value}
        pageTitle={translation.languages}
        customized
        addAnotherField={() => {
          setValue([...value, { language: '', proficiency: '' }])
        }}
      >
        {value.length > 0 ? (
          value.map((item, index) => (
            <div key={index} className='flex flex-col sm:flex-row gap-4'>
              <AutoComplete
                options={languageOptions}
                setOptions={setLanguageOptions}
                value={item.language}
                handleChange={(value, ...props) => {
                  handleChange(value, index, 'language')
                }}
                controlled={false}
                label={index === 0 && 'Sprache'}
                error={errors[index]?.language ? true : false}
                helperText={errors[index]?.language}
                autoHighlight
              />
              <AutoComplete
                options={proficiencyOptions}
                setOptions={setProficiencyOptions}
                value={item.proficiency}
                handleChange={(value) =>
                  handleChange(value, index, 'proficiency')
                }
                controlled={false}
                label={index === 0 && 'Sprachkenntnisse'}
                error={errors[index]?.proficiency ? true : false}
                helperText={errors[index]?.proficiency}
                autoHighlight
              />
              <div
                className={`${
                  index === 0 && 'mt-4'
                } cursor-pointer flex items-center justify-center`}
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </div>
            </div>
          ))
        ) : (
          <EmptyPage />
        )}
      </SimpleEditWrapper>
    </EditSkills>
  )
}

export default VerifiedEditLanguage
