import React, { useState, useEffect } from 'react'
import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import Input from 'components/Input'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import DeleteIcon from 'assets/icons/delete'
import { updateTrial } from './updateTrial'
import useGetDiary from 'hooks/useGetDiary'
import { TrialApprenticeship } from '../EditMotivationLetter/Step1'
import { uuidv4 } from 'utils'
import CustomSelect from 'components/CustomSelect'
import { translation } from 'utils/germanTranslation'
import useGetBack from 'hooks/useGetBack'
import { IconButton } from '@mui/material'

export const NEWEST = 'Neuste'
export const OLDEST = 'Älteste'

const VerifiedEditTrial = ({ id, navigate }) => {
  const initialValue = {
    id: uuidv4(),
    type: TrialApprenticeship,
    job: '',
    companyName: '',
    contactPerson: '',
    contactEmail: '',
    date: { from: '', to: '' },
    notes: '',
    place: '',
    createdAt: new Date(),
  }

  const {
    loading: diaryLoading,
    data: diary,
    sortBy: diarySortBy,
  } = useGetDiary(id)
  const [value, setValue] = useState([initialValue])
  const [unsortedValue, setUnsortedValue] = useState([initialValue])
  const [errors, setErrors] = useState([initialValue])
  const [loading, setLoading] = useState(false)
  const onBackButtonEvent = useGetBack(value)

  const handleChange = (newValue, field, id) => {
    const temp = value.map((object) =>
      object.id === id ? { ...object, [field]: newValue } : object
    )
    setValue(temp)
    setUnsortedValue(temp)
  }

  const handleDateChange = (parsedDate, enteredValue, field, id) => {
    const date =
      parsedDate !== 'Invalid date'
        ? moment(parsedDate, 'DD/MM/YYYY')
        : moment(enteredValue, 'DD/MM/YYYY')
    if (field === 'from') {
      const sortedData = value.map((object) =>
        id === object.id
          ? {
              ...object,
              date: { from: date['_d'], to: object.date.to || date['_d'] },
            }
          : object
      )
      const unsortedData = unsortedValue.map((object) =>
        id === object.id
          ? {
              ...object,
              date: { from: date['_d'], to: object.date.to || date['_d'] },
            }
          : object
      )
      setValue(sortedData)
      setUnsortedValue(unsortedData)
    } else if (field === 'to') {
      const sortedData = value.map((object) =>
        id === object.id
          ? { ...object, date: { ...object.date, to: date['_d'] } }
          : object
      )

      const unsortedData = unsortedValue.map((object) =>
        id === object.id
          ? { ...object, date: { ...object.date, to: date['_d'] } }
          : object
      )

      setValue(sortedData)
      setUnsortedValue(unsortedData)
    }
  }

  useEffect(() => {
    if (diary?.length) {
      setSortBy(diarySortBy)
      setValue(diary)
      setUnsortedValue(diary)
    }
  }, [diary])

  const [sortBy, setSortBy] = useState('')
  const handleSort = (newValue) => setSortBy(newValue)

  useEffect(() => {
    if (sortBy === NEWEST) {
      let temp = [...value]
      const sortedRow = [...value]
        .filter((x) => x.type === TrialApprenticeship)
        .sort((a, b) => {
          return new Date(b.date?.from) - new Date(a.date?.from)
        })
      for (let index = 0; index < temp.length; index++) {
        if (temp[index].type === TrialApprenticeship) {
          temp[index] = sortedRow.shift()
        }
      }
      setValue(temp)
    } else if (sortBy === OLDEST) {
      let temp = [...value]
      const sortedRow = [...value]
        .filter((x) => x.type === TrialApprenticeship)
        .sort((a, b) => {
          return new Date(a.date?.from) - new Date(b.date?.from)
        })
      for (let index = 0; index < temp.length; index++) {
        if (temp[index].type === TrialApprenticeship) {
          temp[index] = sortedRow.shift()
        }
      }
      setValue(temp)
    }
  }, [sortBy])

  const deleteTrial = (id) => {
    const sortedTemp = value.filter((item) => item.id !== id)
    const unsortedTemp = unsortedValue.filter((item) => item.id !== id)
    setValue(sortedTemp)
    setUnsortedValue(unsortedTemp)
  }

  const addTrial = () => {
    const sortedTemp = [...value, initialValue]
    const unsortedTemp = [...unsortedValue, initialValue]
    setValue(sortedTemp)
    setUnsortedValue(unsortedTemp)
  }

  const getLabelClasses = (index) =>
    index === value.findIndex((trial) => trial.type === TrialApprenticeship)
      ? 'block'
      : 'sm:hidden block'

  return (
    <EditSkills
      pageTitle={translation.trialApprenticeship}
      onUpdateClick={() =>
        updateTrial(
          unsortedValue,
          setLoading,
          id,
          navigate,
          sortBy,
          onBackButtonEvent
        )
      }
      loading={diaryLoading}
      disabled={loading || diaryLoading}
    >
      {/* Sorting */}
      <div className='flex items-center justify-end gap-2 w-full mt-4'>
        <div className='w-full sm:w-56 flex items-center'>
          <span className='w-[100%]'>{translation.sortBy}:</span>
          <CustomSelect
            options={[NEWEST, OLDEST]}
            value={sortBy}
            handleChange={handleSort}
          />
        </div>
      </div>
      <SimpleEditWrapper
        pageTitle={translation.trialApprenticeship}
        customized={true}
        addAnotherField={() => addTrial()}
      >
        <div className='flex flex-col gap-4'>
          {value.map((item, index) =>
            item.type === TrialApprenticeship ? (
              <div
                key={item.id}
                className='flex flex-row gap-2 flex-wrap lg:flex-nowrap'
              >
                {/* <div className='flex gap-2 flex-col sm:flex-row w-full lg:w-[60%]'> */}
                <DatePicker
                  label={translation.startingYear}
                  labelClasses={getLabelClasses(index)}
                  inputFormat='dd.MM.yyyy'
                  value={item?.date?.from || ''}
                  handleChange={(...props) =>
                    handleDateChange(...props, 'from', item.id)
                  }
                  error={errors[index]?.date?.from ? true : false}
                  helperText={errors[index]?.date?.from}
                  containerClasses='w-full md:w-[48%] lg:w-[200px]'
                />
                <DatePicker
                  label={translation.endingYear}
                  labelClasses={getLabelClasses(index)}
                  inputFormat='dd.MM.yyyy'
                  value={item?.date?.to || item?.date?.from || ''}
                  // containerClasses='w-full lg:w-auto'
                  handleChange={(...props) =>
                    handleDateChange(...props, 'to', item.id)
                  }
                  error={errors[index]?.date?.to ? true : false}
                  helperText={errors[index]?.date?.to}
                  containerClasses='w-full md:w-[48%] lg:w-[200px]'
                />
                {/* </div> */}
                {/* <div className='flex gap-2 flex-col sm:flex-row w-full lf:w-[40%]'> */}
                <Input
                  label={translation.job}
                  labelClasses={getLabelClasses(index)}
                  value={item.job}
                  handleChange={(...props) =>
                    handleChange(...props, 'job', item.id)
                  }
                  error={errors[index]?.job ? true : false}
                  helperText={errors[index]?.job}
                  width='w-full md:w-[48%] lg:w-[350px]'
                />
                <Input
                  label={translation.companyName}
                  labelClasses={getLabelClasses(index)}
                  value={item.companyName}
                  handleChange={(...props) =>
                    handleChange(...props, 'companyName', item.id)
                  }
                  error={errors[index]?.companyName ? true : false}
                  helperText={errors[index]?.companyName}
                  width='w-full md:w-[48%] lg:w-[300px]'
                />
                {/* {/* </div> */}
                {/* <div className='grid grid-cols-none sm:grid-cols-2 w-full lg:flex lg:w-auto'> */}{' '}
                <Input
                  label={translation.place}
                  labelClasses={getLabelClasses(index)}
                  value={item.place}
                  handleChange={(...props) =>
                    handleChange(...props, 'place', item.id)
                  }
                  error={errors[index]?.place ? true : false}
                  helperText={errors[index]?.place}
                  width='w-full md:w-[48%] lg:w-auto'
                />
                <div className='w-full md:w-auto flex items-center justify-center'>
                  <IconButton className='p-0 h-max mt-[20px]'>
                    <DeleteIcon />
                  </IconButton>
                </div>
                {/* <div
                  className={`${index === 0 && 'mt-4'} ${
                    errors[index]?.date?.from ||
                    errors[index]?.date?.to ||
                    errors[index]?.companyName
                      ? `${
                          index === value?.length - 1
                            ? 'mt-auto sm:mt-8'
                            : 'mt-auto sm:mt-[10px]'
                        }`
                      : 'flex items-center justify-center'
                  } flex lg:block items-center justify-center w-full lg:w-auto`}
                  onClick={() => deleteTrial(item.id)}
                >
                  <DeleteIcon />
                </div> */}
              </div>
            ) : null
          )}
        </div>
      </SimpleEditWrapper>
    </EditSkills>
  )
}

export default VerifiedEditTrial
