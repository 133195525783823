import EditIcon from 'assets/icons/edit'
import React from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

const SkillsCard = ({
  icon,
  title,
  bgColor = 'white',
  width = 332,
  height = 312,
  containerClasses,
  roundedBorderColor,
  titleBorderColor,
  editLink,
  data,
  loading,
  templateClasses,
  template,
  canEdit = false,
  ...props
}) => {
  const titleBorderWidth = String(width).includes('%')
    ? Number(width.replace('%', '')) - 30 + '%'
    : width - (width * 30) / 100

  const navigate = useNavigate()
  return (
    <div
      className={`rounded-3xl shadow-lg text-softBlack ${containerClasses}`}
      style={{ width, height, backgroundColor: bgColor }}
      {...props}
    >
      {loading ? (
        <div className='flex items-center justify-center h-full'>
          <CircularProgress />
        </div>
      ) : (
        <div
          onClick={() => {
            if (canEdit && !data.length) navigate(editLink)
          }}
          className={`h-full w-full flex ${
            data.length === 0 && 'justify-center'
          } items-center flex-col gap-2 md:gap-0 2xl:gap-2 ${
            data.length === 0 ? 'rounded-3xl border-2 border-dashed' : ''
          } ${canEdit && !data.length ? 'cursor-pointer' : ''} `}
          style={{ borderColor: roundedBorderColor }}
        >
          {data.length === 0 && canEdit && <div>{icon}</div>}
          <div
            className={`flex items-center ${
              data.length ? 'justify-between' : 'justify-center'
            }  w-full`}
          >
            <p className='text-lg sm:text-xl font-bold'>
              {data.length === 0 && canEdit
                ? `${title} Hinzufügen `
                : !canEdit && data.length === 0
                ? `Keine ${title}`
                : `${title}`}{' '}
            </p>
            {data.length > 0 && canEdit && (
              <Link to={editLink} className='hover:scale-105 transition-all ease-in-out'>
                <EditIcon />
              </Link>
            )}
          </div>
          {data.length > 0 && (
            <div
              className={`h-[0.5px]`}
              style={{
                width: titleBorderWidth,
                backgroundColor: titleBorderColor,
              }}
            ></div>
          )}
          <div className={`w-full overflow-auto ${templateClasses}`}>
            {data.length > 0 &&
              data.map((skill, index) => template(skill, index))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SkillsCard
