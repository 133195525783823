import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import { TextField } from '@mui/material'

const CustomInput = ({
  label = '',
  placeholder = '',
  value,
  handleChange,
  labelClasses,
  containerClasses = '',
  readOnly = false,
  disableInputPadding = false,
  inputBackgroundColor = 'transparent',
  width = 'w-full',
  ...props
}) => {
  return (
    <div className={`${width} ${containerClasses}`}>
      <InputLabel shrink className={labelClasses}>
        {label}
      </InputLabel>
      <TextField
        placeholder={placeholder}
        fullWidth
        value={value}
        sx={{
          [`& .MuiInputBase-root`]: {
            minHeight: 44,
            backgroundColor: inputBackgroundColor,
          },
          [`& .MuiInputBase-input`]: {
            padding: disableInputPadding ? '0' : '9.5px 14px',
          },
          [`& .MuiAutocomplete-input`]: {
            minHeight: 10,
          },
        }}
        onChange={(e) => handleChange(e.target.value)}
        FormHelperTextProps={{
          style: {
            marginLeft: 0,
          },
        }}
        inputProps={{ readOnly: readOnly }}
        {...props}
      />
    </div>
  )
}

export default CustomInput
