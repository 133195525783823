import jwtDecode from 'jwt-decode'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomModal from './CustomModal'
import CustomInput from './Input'
import Button from './Button'
import axios from 'axios'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'
import { ROUTES } from 'utils/routes'
import { translation } from 'utils/germanTranslation'

const ResetPassword = () => {
  const [decodedToken, setDecodedToken] = useState()
  const navigate = useNavigate()
  const { token } = useParams()
  const [value, setValue] = useState({
    password: '',
    confirmPassword: '',
  })

  useEffect(() => {
    try {
      const decoded = jwtDecode(token)
      setDecodedToken(decoded)
    } catch (error) {
      console.log(error)
    }
  }, [token])

  const handleChange = (newValue, key) => {
    setValue((prevValue) => ({
      ...prevValue,
      [key]: newValue,
    }))
  }

  const setNewPassword = async (e) => {
    e.preventDefault()
    const { password, confirmPassword } = value
    if (password !== confirmPassword) {
      ShowToast({ message: 'Password and Confirm Password do not match' })
      return
    }
    try {
      const { data } = await axios.post(BACKEND_URL + API_URL.setNewPassword, {
        password,
        userId: decodedToken?.id,
      })
      ShowToast({ type: 'success', message: data.message })
      navigate(ROUTES().login)
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  return (
    <div>
      <CustomModal open={true} containerClasses='w-[90%]'>
        <h1 className='text-center'>
          Bitte geben Sie ein neues Passwort für ein
        </h1>
        <h1 className='text-center mb-4 font-bold '>{decodedToken?.email}</h1>
        <CustomInput
          label='Neues Kennwort'
          type='password'
          labelClasses='font-bold text-black'
          value={value.password}
          handleChange={(newValue) => handleChange(newValue, 'password')}
          required
        />
        <CustomInput
          containerClasses='mt-4'
          label='Bestätige Neues Passwort'
          type='password'
          labelClasses='font-bold text-black'
          value={value.confirmPassword}
          handleChange={(newValue) => handleChange(newValue, 'confirmPassword')}
          required
        />
        <Button
          title={translation.submit}
          disabled={
            !value.password ||
            !value.confirmPassword ||
            value.password !== value.confirmPassword
          }
          onClick={setNewPassword}
          type='button'
          containerClasses='bg-[#458EFF] text-white w-[120px] rounded-md h-[40px] m-auto mt-4'
        />
      </CustomModal>
    </div>
  )
}

export default ResetPassword
