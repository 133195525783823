import React from 'react'
import { Box } from '@mui/material'
import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'

const EditWrapper = ({
  children,
  pageTitle,
  description = null,
  onUpdateClick,
  disabled = false,
  showCancelButton = true,
  cancelButtonTitle = translation.cancel.toLowerCase(),
  submitButtonTitle = translation.update,
  navigate,
  onCancelClick = () => navigate(-1),
  containerWidth = 'full',
}) => {
  return (
    <Box
      className={`flex flex-col max-w-[1166px] px-8 ${
        containerWidth === 'full' ? 'w-full' : `w-[${containerWidth}]`
      } gap-4 text-softBlack p-4 pt-10`}
    >
      <div className='flex items-center gap-4'>
        <div>
          <h3 className='text-2xl font-bold'>{pageTitle}</h3>
          {description && (
            <p className='font-semibold text-sm sm:text-base md:text-lg'>
              {description}
            </p>
          )}
        </div>
      </div>
      {children}
      <div className='flex flex-col sm:flex-row items-center justify-end gap-3'>
        {showCancelButton && (
          <Button
            title={cancelButtonTitle}
            titleClasses='text-[#458EFF] text-lg font-semibold'
            containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#E9F1FC]'
            onClick={onCancelClick}
          />
        )}
        <Button
          title={submitButtonTitle}
          onClick={onUpdateClick}
          titleClasses='text-white text-lg font-semibold'
          containerClasses='rounded-md w-full min-w-[138px] sm:w-min h-[60px] bg-[#458EFF] px-2'
          disabled={disabled}
        />
      </div>
    </Box>
  )
}

export default EditWrapper
