import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import DeleteIcon from 'assets/icons/delete'
import axios from 'axios'
import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import CustomInput from 'components/Input'
import useGetClassDetails from 'hooks/useGetClassDetails'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { translation } from 'utils/germanTranslation'
import { ShowToast } from 'utils/toastMessages'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const AddStudentsToClass = ({ role }) => {
  const { classId, id: userId } = useParams()
  const [errorEmails, setErrorEmails] = useState([])
  const { data } = useGetClassDetails(classId)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setErrorEmails([])
  }

  const initialState = [{ email: '' }]
  const [value, setValue] = useState(initialState)

  const handleChange = (field, newValue, index) => {
    setValue(
      value.map((item, i) =>
        index === i ? { ...item, [field]: newValue } : item
      )
    )
  }
  const handleDelete = (index) => {
    setValue(value.filter((item, i) => i !== index))
  }

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.classes[`sendClassInviteTo${role}`],
        {
          [role === 'Teacher' ? 'teacherEmails' : 'studentEmails']: value.map(
            (members) => members.email
          ),
          classId,
          adminTeacherId: userId,
        }
      )
      if (data.success) {
        ShowToast({ type: 'success', message: data.message })
        if (role === 'Teacher') {
          navigate(-1)
        }
      }
      if (data?.erroredEmails.length) {
        setOpen(true)
        setErrorEmails(data.erroredEmails)
      }
    } catch (error) {
      ShowToast({ message: error })
      if (error?.response?.data?.erroredEmails?.length) {
        setOpen(true)
        setErrorEmails(error.response.data.erroredEmails)
      }
    }
  }

  useEffect(() => {
    if (errorEmails.length > 0) {
      handleOpen()
    }
  }, [errorEmails])

  const roleName = role === 'Student' ? 'Schüler*in' : 'Lehrperson'

  return (
    <EditSkills
      pageTitle={`${roleName} zur Klasse hinzufügen "${data?.className}"`}
      onUpdateClick={handleSubmit}
      disabled={value.find((email) => !email.email)}
      containerWidth={'40rem'}
    >
      <SimpleEditWrapper
        pageTitle={roleName}
        value={value}
        customized
        addAnotherField={() => {
          setValue([...value, { email: '' }])
        }}
      >
        {value.map((item, index) => (
          <div
            key={index}
            className='flex flex-col items-center sm:flex-row gap-4 w-[400px] m-auto'
          >
            <CustomInput
              label={`Email ${roleName}`}
              value={item.email}
              onChange={(newValue) =>
                handleChange('email', newValue.target.value, index)
              }
            />
            <div onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </div>
          </div>
        ))}
      </SimpleEditWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='flex flex-col gap-2'>
          {errorEmails.length > 0 ? (
            <>
              <div>
                Im Folgenden sind die E-Mails aufgeführt, die dem Kurs nicht
                hinzugefügt werden konnten
              </div>
              {errorEmails.length > 0 && (
                <div>
                  {errorEmails.map((user, index) => (
                    <p className='list-item list-inside' key={index}>
                      <span className='text-red-600'>{user.email}</span> -{' '}
                      {user.reason}
                    </p>
                  ))}
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </Box>
      </Modal>
    </EditSkills>
  )
}

export default AddStudentsToClass
