import React, { useState, useEffect } from 'react'
import AutoComplete from 'components/AutoComplete'
import { documentOptions as docOptions } from 'components/Student/EditDocuments/data'
import DeleteIcon from 'assets/icons/delete'
import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import UploadFiles from 'components/Upload'
import { updateDocuments } from './updateDocuments'
import axios from 'axios'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import useGetUserDocuments from 'hooks/useGetUserDocuments'
import VerifyEditAccess from 'components/VerifyEditAccess'
import { user as userFromRedux } from 'store/reducers/user'
import { useSelector } from 'react-redux'
import { useRef } from 'react'
import { toast } from 'react-toastify'
import { ShowToast } from 'utils/toastMessages'
import { useSearchParams } from 'react-router-dom'
import NoAccess from 'components/NoAccess'
import UpdateRank from 'components/MoveUpOrDown'
import { translation } from 'utils/germanTranslation'
import { capitalize } from '../Dashboard/StudentIntroduction/Documents'
import { WORK_TRIAL } from 'utils/documentTypes'
import { Info } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

const DOCUMENT_VISIBLE = 'Schnupperbericht einblenden'
const DOCUMENT_HIDDEN = 'Schnupperbericht ausblenden'

const EditDocuments = () => (
  <VerifyEditAccess>
    {(id, canEdit, navigate) =>
      canEdit ? (
        <VerifiedEditDocuments id={id} navigate={navigate} />
      ) : (
        <NoAccess />
      )
    }
  </VerifyEditAccess>
)

const VerifiedEditDocuments = ({ id, navigate }) => {
  const loggedInUser = useSelector(userFromRedux)
  const [params] = useSearchParams()
  const documentType = params.get('type')
  const type =
    docOptions.find((option) => option.title === documentType)?.title || ''
  const { data: documents } = useGetUserDocuments(id, documentType)

  const [documentOptions, setDocumentOptions] = useState(docOptions)
  const [value, setValue] = useState([
    { file: null, type: capitalize(type), isVisible: true },
  ])
  const [loading, setLoading] = useState(false)
  const toastId = useRef(null)

  const handleDelete = (index) => {
    handleDocumentDelete(index)
    setValue((prev) => prev.filter((_, i) => i !== index))
  }

  const handleChange = (newValue, index, field) => {
    const temp = [...value].map((object, i) =>
      i === index
        ? {
            ...object,
            [field]:
              newValue !== undefined && newValue !== null ? newValue : '',
          }
        : object
    )
    setValue(temp)
  }

  const handleDocumentUpload = (file, index) => {
    const formData = new FormData()
    formData.append('document', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${loggedInUser?.token}`,
        id,
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: false })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
            autoClose: false,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 0,
          })
          toastId.current = null
        }
      },
    }
    axios
      .post(BACKEND_URL + API_URL.documents.add, formData, config)
      .then(({ data }) => {
        handleChange(data?.data, index, 'file')
      })
      .catch((error) => {
        ShowToast({ error })
      })
  }

  const downloadDocument = async (file) => {
    const { data } = await axios.get(BACKEND_URL + '/' + file?.path, {
      responseType: 'blob',
    })
    // console.log(data);
    let link = document.createElement('a')
    if (link.download !== undefined) {
      var url = URL.createObjectURL(data)
      link.setAttribute('href', url)
      link.setAttribute('download', file?.title)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleDocumentDelete = (index) => {
    const fileToRemove = value[index]?.file
    if (fileToRemove) {
      axios
        .post(BACKEND_URL + API_URL.documents.delete, fileToRemove, {
          headers: {
            Authorization: `Bearer ${loggedInUser?.token}`,
            id,
          },
        })
        .then(() => {})
        .catch((error) => {})
    }
  }

  useEffect(() => {
    if (documents?.length > 0) {
      setValue(documents)
    }
  }, [documents])

  const validate = () => {
    for (let index = 0; index < value.length; index++) {
      if (!value[index].file || !value[index].type) {
        return false
      }
    }
    return true
  }

  return (
    <EditSkills
      pageTitle='Anhänge bearbeiten'
      description='Wählen Sie in der linken Spalte eine Datei und in der rechten Spalte den Dateityp aus'
      onUpdateClick={() =>
        updateDocuments(value, setLoading, id, navigate, documentType)
      }
      disabled={!validate()}
    >
      <SimpleEditWrapper
        value={value}
        pageTitle={translation.files}
        addAnotherField={() =>
          setValue([...value, { file: null, type, isVisible: true }])
        }
        customized
      >
        {value.map((item, index) => (
          <div
            key={index}
            className='flex flex-col sm:flex-row items-center gap-4'
          >
            <UpdateRank
              key={index}
              value={value}
              index={index}
              setValue={setValue}
            />
            <div className='grid grid-cols-none sm:grid-cols-8 items-center gap-4 w-[95%]'>
              <UploadFiles
                file={item.file}
                onDeleteFile={() => {
                  handleChange('', index, 'file')
                  handleDocumentDelete(index)
                }}
                handleFileUpload={(file) => handleDocumentUpload(file, index)}
                types={['pdf']}
                onDownloadFile={() => downloadDocument(item?.file)}
                containerClasses={
                  type !== WORK_TRIAL ? 'col-span-4' : 'col-span-3'
                }
              />
              <AutoComplete
                options={documentOptions.map(({ title }) => {
                  return { title: capitalize(title) }
                })}
                setOptions={setDocumentOptions}
                value={capitalize(item.type)}
                handleChange={(newValue) => {
                  handleChange(String(newValue).toLowerCase(), index, 'type')
                }}
                controlled={false}
                containerClasses={
                  type !== WORK_TRIAL ? 'col-span-4' : 'col-span-2'
                }
              />
              {type === WORK_TRIAL && (
                <div className='w-full col-span-3 flex '>
                  <AutoComplete
                    options={[DOCUMENT_VISIBLE, DOCUMENT_HIDDEN]}
                    setOptions={setDocumentOptions}
                    value={item.isVisible ? DOCUMENT_VISIBLE : DOCUMENT_HIDDEN}
                    handleChange={(newValue) => {
                      handleChange(
                        newValue.includes(DOCUMENT_VISIBLE) ? true : false,
                        index,
                        'isVisible'
                      )
                    }}
                    controlled={true}
                  />
                  <Tooltip title='Du kannst hier auch Berichte ablegen, die weniger gut waren. Wähle einfach “Schnupperbericht ausblenden” an – so wird er nur hier gespeichert und nirgendwo geteilt!'>
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
            <div
              className='flex items-center justify-center'
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon />
            </div>
          </div>
        ))}
      </SimpleEditWrapper>
    </EditSkills>
  )
}

export default EditDocuments
