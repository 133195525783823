import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetClasses = (id, classId) => {
  const [value, setValue] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const url = classId
        ? `${API_URL.classes.findById}${classId}`
        : API_URL.classes.all;
      const { data: userData } = await axios.get(BACKEND_URL + url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const classes = userData.data;

      setValue(classes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return { loading, data: value, refetch: getData, error };
};

export default useGetClasses;
