import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const CustomModal = ({
  open,
  handleClose,
  containerClasses,
  children,
  maxWidth = '693',
  padding = '4',
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      {...props}
    >
      <Box
        className={`bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-md rounded-md p-${padding} h-auto max-h-[625px] max-w-[${maxWidth}px]  ${
          containerClasses || 'w-full'
        }`}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
