import React, { useEffect, useRef, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ROUTES } from 'utils/routes'
import VerifyEditAccess from 'components/VerifyEditAccess'
import NoAccess from 'components/NoAccess'
import CustomModal from 'components/CustomModal'
import Button from 'components/Button'
import { deleteDiaryById } from './deleteDiaryById'
import { useNavigate } from 'react-router-dom'
import Tag from 'components/Tag'
import AddIcon from 'assets/icons/add'
import useGetMotivationLetters from 'hooks/useGetMotivationLetters'
import { useSelector } from 'react-redux'
import { user } from 'store/reducers/user'
import { FileUploader } from 'react-drag-drop-files'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import axios from 'axios'
import { toast } from 'react-toastify'
import MotivationLetterRowTemplate from './RowTemplate'
import DetailPageWithTable from '../Diary/DetailPageWithTable'
import { translation } from 'utils/germanTranslation'

const GERMEN_CREATE_LETTER = 'Motivationsschreiben erstellen'
const GERMAN_UPLOAD_LETTER = 'Motivationsschreiben hochladen'

const MotivationLetterLibrary = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit) =>
        canEdit ? <VerifiedMotivationLetterLibrary id={id} /> : <NoAccess />
      }
    </VerifyEditAccess>
  )
}

const initialModalDetails = {
  showDeleteLetterModal: false,
  showUploadLetterModal: false,
  index: null,
  letterTitle: '',
  letterFile: null,
}

const NEWEST = 'Neuste'
const JOB_TITLE = 'Beruf'
const COMPANY_NAME = 'Name der Firma'

const VerifiedMotivationLetterLibrary = ({ id }) => {
  const loggedInUser = useSelector(user)
  const [modalDetails, setModalDetails] = useState(initialModalDetails)
  const [value, setValue] = useState([])
  const { loading, data, refetch } = useGetMotivationLetters(id)
  const toastId = useRef(null)

  const navigate = useNavigate()

  useEffect(() => {
    setValue(data)
    handleSort(NEWEST, [...data])
  }, [data])

  const handleModalClose = () => setModalDetails(initialModalDetails)
  const [sortBy, setSortBy] = useState('')

  const handleSort = (sortType, dataToSort) => {
    if (sortType === NEWEST) {
      const sortedRow = [...dataToSort].sort((a, b) => {
        return new Date(b.modifiedAt) - new Date(a.modifiedAt)
      })
      setValue(sortedRow)
    } else if (sortType === JOB_TITLE) {
      const sortedRow = [...dataToSort]
        .filter((letter) => letter.type !== 'upload')
        .sort((a, b) => {
          return a.job.title.localeCompare(b.job.title)
        })

      setValue([
        ...sortedRow,
        ...dataToSort.filter((letter) => letter.type === 'upload'),
      ])
    } else if (sortType === COMPANY_NAME) {
      const sortedRow = [...dataToSort]
        .filter((letter) => letter.type !== 'upload')
        .sort((a, b) => {
          return a.company.name.localeCompare(b.company.name)
        })
      setValue([
        ...sortedRow,
        ...dataToSort.filter((letter) => letter.type === 'upload'),
      ])
    }
    setSortBy(sortType)
  }

  const handleLetterUpload = (letterFile, letterTitle = '') => {
    const formData = new FormData()
    formData.append('document', letterFile)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${loggedInUser?.token}`,
        id,
        title: letterTitle.substring(0, letterTitle.length - 4),
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: false })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
            autoClose: false,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 0,
          })
          toastId.current = null
        }
      },
    }
    axios
      .post(BACKEND_URL + API_URL.motivationLetters.upload, formData, config)
      .then(() => {
        refetch()
      })
      .catch((error) => {})
  }

  return (
    <>
      <DetailPageWithTable
        pageTitle='Motivationsschreiben-Bibliothek'
        userId={id}
        loading={loading}
        rows={value}
        sortingOptions={[NEWEST, JOB_TITLE, COMPANY_NAME]}
        sortBy={sortBy}
        setSortBy={setSortBy}
        handleSortBy={(type) => handleSort(type, data)}
        setRows={setValue}
        emptyPageMessage='Noch kein Motivationsschreiben erstellt'
        AddButton={
          <Tag
            title={GERMEN_CREATE_LETTER}
            icon={<AddIcon />}
            showBorder={false}
            titleClasses='text-sm font-semibold'
            containerClasses='pt-4'
            onClick={() => {
              navigate(ROUTES({ userId: id }).student.edit.motivationLetter)
            }}
          />
        }
        buttons={[
          {
            title: GERMEN_CREATE_LETTER,
            containerClasses:
              'bg-[#458EFF] w-full md:w-[200px] min-w-[7rem] h-[56px] rounded-md',
            titleClasses: 'text-white',
            link: ROUTES({ userId: id }).student.edit.motivationLetter,
          },
          <FileUploader
            multiple={false}
            handleChange={(file) => {
              handleLetterUpload(file, file.name)
            }}
            name='file'
            types={['pdf']}
            hoverTitle='Drop your PDF here'
          >
            <Button
              title={GERMAN_UPLOAD_LETTER}
              containerClasses='bg-[#E9F1FC] w-full md:w-[200px] min-w-[7rem] h-[56px] rounded-md'
              titleClasses='text-[#458EFF] font-semibold'
            />
          </FileUploader>,
        ]}
        HeaderTemplate={() => (
          <TableHead className='bg-[#F0F2F9]'>
            <TableRow>
              <TableCell style={{ minWidth: '9rem' }}>
                {translation.title}
              </TableCell>
              <TableCell>{translation.companyName}</TableCell>
              <TableCell>{translation.job}</TableCell>
              <TableCell>{translation.date}</TableCell>
              <TableCell>{translation.type}</TableCell>
              <TableCell style={{ minWidth: '9rem', textAlign: 'center' }}>
                {translation.actions}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        rowTemplate={(row, index) => (
          <MotivationLetterRowTemplate
            key={index}
            row={row}
            index={index}
            userId={id}
            onDelete={() => {
              setModalDetails({
                ...initialModalDetails,
                showDeleteLetterModal: true,
                index: row?.id,
              })
            }}
            user={loggedInUser}
            refetch={refetch}
          />
        )}
      />
      <CustomModal
        open={modalDetails.showDeleteLetterModal}
        handleClose={handleModalClose}
        containerClasses='w-[90%] sm:w-auto p-3'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Möchten Sie diesen Brief wirklich entfernen?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.sure}
              onClick={() => {
                deleteDiaryById(
                  id,
                  modalDetails.index,
                  handleModalClose,
                  refetch
                )
              }}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={handleModalClose}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default MotivationLetterLibrary
