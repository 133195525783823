import { Box } from '@mui/material'
import CVGeneratorIcon from 'assets/icons/cvGenerator'
import InterviewIcon from 'assets/icons/interview'
import JobInformationIcon from 'assets/icons/jobInformation'
import MotivationLetterIcon from 'assets/icons/motivationLetter'
import Login from 'components/Login'
import Register from 'components/Register'
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'utils/routes'
import CookiePopup from './CookiePopup'

const IconWidth = 50
const IconHeight = 50

const landingPageOptions = [
  {
    title: 'Lebenslauf erstellen',
    link: 'https://lehrstell-tools.ch/home',
    isExternalLink: true,
    icon: <CVGeneratorIcon width={IconWidth} height={IconHeight} />,
  },
  {
    title: 'Motivationsschreiben erstellen',
    link: 'https://lehrstell-tools.ch/home',
    isExternalLink: true,
    icon: <MotivationLetterIcon width={IconWidth} height={IconHeight} />,
  },
  {
    title: 'Bewerbungsgespräch vorbereiten',
    link: 'https://lehrstell-tools.ch/home',
    isExternalLink: true,
    icon: <InterviewIcon width={IconWidth} height={IconHeight} />,
  },
  {
    title: 'Infos über Lehrberufe',
    link: ROUTES().student.job.all,
    isExternalLink: false,
    icon: <JobInformationIcon width={IconWidth} height={IconHeight} />,
  },
]

const Option = ({ title, link, isExternalLink, icon }) => {
  const linkClassName =
    'w-full flex gap-3 items-center bg-[#FEEC9A] text-softBlack p-0 rounded hover:scale-[1.02] transform transition duration-300 ease-in-out'
  return (
    <>
      {isExternalLink ? (
        <a
          href={link}
          target='_blank'
          rel='noreferrer'
          className={linkClassName}
        >
          <div>{icon}</div>
          {title}
        </a>
      ) : (
        <Link className={linkClassName} to={link}>
          <div>{icon}</div>
          {title}
        </Link>
      )}
    </>
  )
}

const LandingPage = ({ isRegister }) => {
  return (
    <>
      <Box className='bg-[#F0F1F5] min-h-screen p-4 sm:p-8 lg:p-12'>
        <div className='max-w-[1400px] grid scale grid-cols-none md:grid-cols-2 items-start m-auto gap-4 '>
          <div>
            <div className='w-full max-w-full md:max-w-[500px] bg-white rounded-md shadow-md flex items-center justify-center flex-col gap-4 p-4 border-2 border-[#44444444]'>
              {landingPageOptions.map((option) => (
                <Option
                  key={option.title}
                  title={option.title}
                  link={option.link}
                  isExternalLink={option.isExternalLink}
                  icon={option.icon}
                />
              ))}
              <h3 className='text-center'>Diese 4 Tools sind für dich ohne Login frei verfügbar</h3>
            </div>
            <CookiePopup />
          </div>
          <div className='flex justify-center md:justify-end'>
            {isRegister ? <Register /> : <Login />}
          </div>
        </div>
      </Box>
    </>
  )
}

export default LandingPage
