import React, { useState } from 'react'
import SkillsCard from './SkillsCard'
import AddLanguageIcon from 'assets/icons/addLanguages'
import AddAcademicHistory from 'assets/icons/addAcademicHistory'
import AddHobbies from 'assets/icons/addHobbies'
import StudentIntro from './StudentIntroduction'
import useGetAllLanguages from 'hooks/useGetAllLanguages'
import useGetAcademicHistory from 'hooks/useGetAcademicHistory'
import moment from 'moment'
import useGetHobbies from 'hooks/useGetHobbies'
import axios from 'axios'
import useGetUserDetail from 'hooks/useGetUserDetail'
import { ROUTES } from 'utils/routes'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'
import { useRef } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from 'store/reducers/user'
import { user as userFromRedux } from 'store/reducers/user'
import CustomImageCropModal from 'components/CustomImageCropModal'
import { Typography } from '@mui/material'
import useGetDiary from 'hooks/useGetDiary'
import { TrialApprenticeship } from '../EditMotivationLetter/Step1'
import _ from 'lodash'
import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'

const StudentDetails = ({
  id,
  canEdit,
  setToggleProfile,
  personalData,
  isClassTeacher,
  navigate,
}) => {
  const toastId = useRef(null)
  const previewCanvasRef = useRef(null)
  const dispatch = useDispatch()
  const {
    loading: userDetailLoading,
    data: user,
    refetch: refetchUser,
  } = useGetUserDetail(id)
  const { loading: languagesLoading, data: languages } = useGetAllLanguages(id)
  const { loading: hobbiesLoading, data: hobbies } = useGetHobbies(id)
  const { loading: academicHistoryLoading, data: academicHistory } =
    useGetAcademicHistory(id)
  const { loading: diaryLoading, data: diary } = useGetDiary(id, null, true)
  const loggedInUser = useSelector(userFromRedux)

  // State
  const [showCropModal, setShowCropModal] = useState(false)
  const [crop, setCrop] = useState()
  const [imgSrc, setImgSrc] = useState('')
  const [loadingImage, setLoadingImage] = useState(false)

  const handleFile = (file) => {
    setLoadingImage(true)
    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const image = new Image()
      image.src = reader.result?.toString() || ''
      image.onload = () => {
        const { width, height } = image
        if (width < 300 && height < 400) {
          ShowToast({
            message: 'Image must be at least 300x400px',
            type: 'error',
          })
          setLoadingImage(false)
          setShowCropModal(false)
          return
        }
        setImgSrc(reader.result?.toString() || '')
        setLoadingImage(false)
      }
    })
    setShowCropModal(true)
    reader.readAsDataURL(file)
  }

  const handleUploadUserImage = async () => {
    const dataUrl = previewCanvasRef.current.toDataURL('image/jpeg')
    const blob = await fetch(dataUrl).then((r) => r.blob())
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
    const formData = new FormData()
    formData.append('photo', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${loggedInUser.token}`,
        id,
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: 200 })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            closeButton: false,
          })
        }
      },
    }
    setShowCropModal(false)
    axios
      .post(BACKEND_URL + API_URL.user.upload, formData, config)
      .then((res) => {
        toast.dismiss(toastId.current, {
          render: translation.uploadComplete,
          type: toast.TYPE.SUCCESS,
          autoClose: 500,
          closeButton: false,
        })
        toastId.current = null
        handleAvatarDelete()
        refetchUser(id)
        if (id === loggedInUser.id) {
          dispatch(fetchUser())
        }
      })
      .catch((error) => {
        ShowToast({ error })
      })
  }

  const handleAvatarDelete = () => {
    const path = user?.avatar
    if (path) {
      axios.post(
        BACKEND_URL + API_URL.user.deleteAvatar,
        { path },
        {
          headers: {
            Authorization: `Bearer ${loggedInUser.token}`,
            id,
          },
        }
      )
    }
  }

  return (
    <div className='w-full md:w-[65%] lg:w-[80%] pt-12 sm:pt-3 2xl:pt-6'>
      {isClassTeacher ? (
        <Button
          title='Back'
          titleClasses='text-[#458EFF] text-lg font-semibold'
          containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#E9F1FC] mt-4 xs:mt-2'
          onClick={() => navigate(-1)}
        />
      ) : null}
      {!_.isEmpty(user) && (
        <StudentIntro
          userId={id}
          user={user}
          canEdit={canEdit}
          loading={userDetailLoading}
          handleFile={handleFile}
          setToggleProfile={setToggleProfile}
          showCropModal={showCropModal}
          setShowCropModal={setShowCropModal}
          loggedInUser={loggedInUser}
          languages={languages}
          hobbies={hobbies}
          academicHistory={academicHistory}
          trial={diary.filter((item) => item.type === TrialApprenticeship)}
          personalData={personalData}
        />
      )}
      <CustomImageCropModal
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        crop={crop}
        setCrop={setCrop}
        handleUploadUserImage={handleUploadUserImage}
        previewCanvasRef={previewCanvasRef}
        closeCropModal={() => setShowCropModal(false)}
        loadingImage={loadingImage}
        setShowCropModal={setShowCropModal}
        showCropModal={showCropModal}
        aspect={3 / 4}
      />
      <div className='grid grid-cols-none lg:grid-cols-11 gap-6 py-12 sm:py-2 2xl:py-5 auto-rows-max'>
        <div className='col-span-full lg:col-span-5'>
          <SkillsCard
            title={translation.languages}
            bgColor='#F7F3E0'
            width={'100%'}
            height={220}
            icon={<AddLanguageIcon />}
            roundedBorderColor='#FBE792'
            titleBorderColor='#F0CF21'
            containerClasses='p-4'
            editLink={ROUTES({ userId: id }).student.edit.languages}
            data={languages || []}
            loading={languagesLoading}
            canEdit={canEdit}
            template={(skill, index) => (
              <div
                key={index}
                className='flex flex-col sm:flex-row justify-between mb-2 font-semibold'
              >
                <p className='truncate max-w-[218px] text-sm'>
                  {skill.language}
                </p>
                <Typography className='font-semibold text-sm'>
                  {skill.proficiency}
                </Typography>
              </div>
            )}
          />
        </div>
        <div className='col-span-full lg:col-span-6'>
          <SkillsCard
            title={'Schulbildung'}
            bgColor='#F7F1FF'
            width={'100%'}
            height={220}
            // showBorder
            icon={<AddAcademicHistory />}
            roundedBorderColor='#D7C9FF'
            titleBorderColor='#5627FF'
            containerClasses='p-4'
            editLink={ROUTES({ userId: id }).student.edit.academic}
            data={academicHistory || []}
            loading={academicHistoryLoading}
            canEdit={canEdit}
            template={(skill, index) => (
              <div
                key={index}
                className='font-semibold flex flex-col sm:flex-row justify-between mb-2 text-sm'
              >
                <p>
                  {skill.startingYear} - {skill.endingYear}
                </p>
                <p className=''>{skill.schoolName + ' ' + skill.place}</p>
              </div>
            )}
          />
        </div>
        <div className='col-span-full lg:col-span-5'>
          <SkillsCard
            title={translation.hobbies}
            bgColor='#E2F2F0'
            width={'100%'}
            height={260}
            icon={<AddHobbies />}
            containerClasses='p-4'
            roundedBorderColor='#AEE0D2'
            titleBorderColor='#59C1A2'
            data={hobbies || []}
            loading={hobbiesLoading}
            editLink={ROUTES({ userId: id }).student.edit.hobbies}
            canEdit={canEdit}
            template={(skill, index) => (
              <div key={index} className='mb-2 font-semibold text-sm'>
                <p className='font-bold'>{skill.title}</p>
                <p>{skill.description}</p>
              </div>
            )}
          />
        </div>
        <div className='col-span-full lg:col-span-6'>
          <SkillsCard
            title={'Schnupperlehren'}
            bgColor='#F7F1FF'
            width={'100%'}
            height={260}
            icon={<AddAcademicHistory />}
            roundedBorderColor='#D7C9FF'
            titleBorderColor='#5627FF'
            containerClasses='p-4'
            data={diary || []}
            loading={diaryLoading}
            editLink={ROUTES({ userId: id }).student.edit.trial}
            canEdit={canEdit}
            templateClasses='flex flex-col'
            template={(skill, index) => (
              <div key={index}>
                {skill?.type === TrialApprenticeship && (
                  <div className='font-semibold flex flex-col sm:flex-row mb-2 gap-0 sm:gap-4 text-sm'>
                    <p className='max-w-[11rem]'>{`${moment(
                      skill?.date?.from
                    ).format('DD.MM')}-${moment(skill?.date?.to).format(
                      'DD.MM.YY'
                    )}`}</p>
                    <p className='overflow-hidden'>
                      {[skill.job, skill.companyName, skill.place]
                        .filter(Boolean)
                        .join(', ')}
                    </p>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default StudentDetails
