import { useState } from 'react'
import { Link } from 'react-router-dom'
import { TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'

import DetailPageWithTable from 'components/Student/Diary/DetailPageWithTable'
import useGetClasses from 'hooks/useGetClasses'
import EditIcon from 'assets/icons/edit'
import TrashIcon from 'assets/icons/trash'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import CustomModal from 'components/CustomModal'
import CustomInput from 'components/Input'
import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'

const VerifiedTeacherClasses = ({ userId }) => {
  const [modalConfig, setModalConfig] = useState({
    showRenameModal: false,
    showDeleteModal: false,
    classId: '',
    className: '',
    newClassName: '',
  })

  const deleteClass = async (id) => {
    await axios.post(BACKEND_URL + API_URL.classes.deleteById + id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    refetch()
  }

  const { loading, data: classes, refetch } = useGetClasses(userId, null)

  const handleCloseModal = () => {
    setModalConfig({
      showRenameModal: false,
      showDeleteModal: false,
      classId: '',
      className: '',
      newClassName: '',
    })
  }

  const handleJobRename = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.put(
        BACKEND_URL + API_URL.classes.updateClassName,
        {
          className: modalConfig.newClassName,
          classId: modalConfig.classId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data?.success) {
        handleCloseModal()
        refetch()
      }
    } catch (error) {}
  }

  return (
    <>
      <DetailPageWithTable
        pageTitle={translation.classes}
        userId={userId}
        loading={loading}
        rows={classes || []}
        buttons={[
          {
            title: `${translation.class} ${translation.add.toLowerCase()} +`,
            containerClasses:
              'bg-[#458EFF] w-full md:w-[200px] min-w-[7rem] h-[56px] rounded-md',
            titleClasses: 'text-white',
            link: 'add',
          },
        ]}
        HeaderTemplate={() => (
          <TableHead className='bg-[#F0F2F9]'>
            <TableRow>
              <TableCell style={{ minWidth: '9rem' }}>
                {translation.className}
              </TableCell>
              <TableCell style={{ width: '9rem' }}>
                {translation.actions}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        rowTemplate={(row, index) => (
          <TableRow key={row.id}>
            <TableCell>
              <Link to={`${row.id}`}>
                <h1 className='hover:underline underline-offset-4 transition-all'>
                  {row.name}
                </h1>
              </Link>
            </TableCell>
            <TableCell className='py-4'>
              {row?.admins?.includes(userId) && (
                <div className='flex gap-2'>
                  <Link to={`${row.id}`}>
                    <EditIcon />
                  </Link>
                  <button
                    onClick={() => {
                      setModalConfig({
                        showRenameModal: false,
                        showDeleteModal: true,
                        classId: row?.id,
                        className: row?.name,
                        newClassName: row?.name,
                      })
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              )}
            </TableCell>
          </TableRow>
        )}
      />
      <CustomModal
        open={modalConfig.showRenameModal}
        containerClasses='w-[90%] max-w-[500px]'
        handleClose={handleCloseModal}
      >
        <form onSubmit={handleJobRename}>
          <h1 className='font-semibold text-lg pb-4'>{`${translation.update} ${translation.className}`}</h1>
          <CustomInput
            label={translation.className}
            value={modalConfig?.newClassName}
            handleChange={(newValue) =>
              setModalConfig({ ...modalConfig, newClassName: newValue })
            }
          />
          <Button
            title={translation.update}
            containerClasses='bg-[#458EFF] rounded-md w-full px-2 py-2 my-4'
            titleClasses='text-white'
            disabled={
              modalConfig?.className.trim() ===
                modalConfig?.newClassName.trim() ||
              !modalConfig?.newClassName.trim()
            }
            type='submit'
          />
        </form>
      </CustomModal>
      <CustomModal
        open={modalConfig.showDeleteModal}
        handleClose={handleCloseModal}
        containerClasses='w-[90%] sm:w-auto'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Möchten Sie diese Klasse wirklich entfernen{' '}
            <span className='text-blue-600 font-bold'>
              ({modalConfig?.className})
            </span>
            ?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.sure}
              onClick={() => {
                deleteClass(modalConfig.classId)
                handleCloseModal()
              }}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={handleCloseModal}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default VerifiedTeacherClasses
