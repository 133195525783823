import React from 'react'

import Navbar from 'components/Navbar/Navbar'

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen p-6 w-full flex gap-4 items-center justify-center text-softBlack'>
        <div className='w-full font-bold text-2xl text-softBlack underline'>
          Nutzungsbedingungen
        </div>
        <div className='bg-white rounded-lg p-4'>
          <span className='font-bold'>Datenschutzerklärung</span>
          <br />
          <p>
            Die Betreiber dieser Seite nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>&nbsp;</p>
          <span className='font-bold'>Cookies </span>
          <p>
            Auf unserer Website werden Cookies verwendet. Cookies richten auf
            Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies
            dienen dazu, unsere Tools effektiver und sicherer zu machen. Cookies
            sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
            die Ihr Browser speichert. Unser Cookie speichert die Daten, damit
            die Eingaben bis zum Download des Lebenslauf oder des
            Motivationsschreibens nicht verloren gehen. Beim Verlassen der
            Website bleiben die Daten im Browser des Benutzers (nicht auf
            unserer Website) gespeichert. Wenn der Benutzer das nächste Mal auf
            unsere Website kommt, sind seine Informationen dann bereits
            vorhanden. Dies erleichtert das erneute Erstellen eines Lebenslaufs
            oder eines Motivationsschreibens. Durch klicken von "Alle Daten
            löschen" auf unserer Website, werden die Informationen gelöscht.
          </p>
          <br />
          <span className='font-bold'>Kontakt</span>
          <p>
            Wenn Sie uns Anfragen zukommen lassen, werden die von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <br />
          <span className='font-bold'>Google Analytics</span>
          <p>
            Unsere Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
            Mountain View, CA 94043, USA. Google Analytics verwendet auch
            Cookies, welche eine Analyse der Benutzung der Website durch Sie
            ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
            Benutzung dieser Website werden in der Regel an einen Server von
            Google in den USA übertragen und dort gespeichert. Mehr
            Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden
            Sie in der Datenschutzerklärung von Google:
            {/* <a
            href='http://https://support.google.com/analytics/answer/6004245?hl=de'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a> */}
          </p>
        </div>
      </div>
    </>
  )
}

export default TermsOfUse
