import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
} from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ACADEMIC, HOBBIES, LANGUAGES, REFERENCES, TRIAL } from './Constants';
import CVAcademicHistory from './CVAcademicHistory';
import Title from './Title';
import CalibriRegular from '../../assets/fonts/Calibri.ttf';
import CalibriBold from '../../assets/fonts/Calibri-Bold.ttf';
import { BACKEND_URL } from 'utils/apiConstants';
import { NoSiblings } from 'utils';

export const NoTrialCompleted = 'Noch keine Schnupperlehre absolviert';

const CVGenerator = ({
  languages,
  hobbies,
  academicHistory,
  trial,
  personalData,
  profilePicture,
}) => {
  const getSiblingsInOrder = () => {
    let siblingsName = '';
    if (!_.isEmpty(personalData)) {
      let siblings = personalData.siblings;
      siblings.forEach((item, index) => {
        siblingsName += `${item.siblingName}${
          item.siblingOccupation ? ' (' + item.siblingOccupation + ')' : ''
        }`;
        if (index !== siblings.length - 1) {
          siblingsName += ', ';
        }
      });
    }
    return siblingsName || NoSiblings;
  };

  const getSortedAcademicHistory = () => {
    let sortedEducation = [];
    if (!_.isEmpty(academicHistory)) {
      sortedEducation = [...academicHistory].sort((a, b) => {
        return new Date(b.startingYear) - new Date(a.startingYear);
      });
    }
    return sortedEducation;
  };

  const getSortedTrial = () => {
    let anyTrialCompleted = false;
    if (!_.isEmpty(trial)) {
      for (let index = 0; index < trial.length; index++) {
        if (moment(trial[index]?.date?.to).isSameOrBefore(moment(new Date()))) {
          anyTrialCompleted = true;
          break;
        }
      }
    }
    return !anyTrialCompleted ? NoTrialCompleted : trial;
  };

  const userAddress1 = `${
    personalData?.address && (personalData?.postalCode || personalData?.place)
      ? personalData?.address + ' '
      : personalData?.address
  }`;
  const userAddress2 = `${
    personalData?.postalCode && personalData?.place
      ? personalData?.postalCode + ' '
      : personalData?.postalCode
  }${personalData?.place && personalData?.place}`;

  const sortedTrials = getSortedTrial();

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Lebenslauf</Text>
        <Image
          src={`${BACKEND_URL}/${profilePicture}`}
          style={styles.userImage}
        />
        <Title heading='Vorname' value={personalData?.firstName} />
        <Title heading='Nachname' value={personalData?.lastName} />
        <Title heading='Adresse' value={userAddress1 || ''} />
        <Title heading='' value={userAddress2 || ''} />
        <Title heading='Telefon' value={personalData?.phone} />
        <Title heading='Email' value={personalData?.email} />
        <Title heading='Nationalität' value={personalData?.nationality} />
        <Title
          heading='Geburtsdatum'
          value={
            personalData?.dateOfBirth
              ? `${moment(personalData?.dateOfBirth).date()}. ${moment(
                  personalData?.dateOfBirth
                ).format('MMMM')} ${moment(personalData?.dateOfBirth).year()}`
              : ''
          }
        />
        {(personalData?.fatherFirstName || personalData?.fatherLastName) && (
          <Title
            heading='Eltern'
            value={`${personalData?.fatherFirstName} ${
              personalData?.fatherLastName
            } ${
              personalData?.fatherOccupation &&
              '(' + personalData?.fatherOccupation + ')'
            }`}
          />
        )}
        {(personalData?.motherFirstName || personalData?.motherLastName) && (
          <Title
            heading={
              _.isEmpty(personalData?.fatherFirstName) &&
              _.isEmpty(personalData?.fatherLastName)
                ? 'Eltern'
                : ''
            }
            value={`${personalData?.motherFirstName} ${
              personalData?.motherLastName
            } ${
              personalData?.motherOccupation &&
              '(' + personalData?.motherOccupation + ')'
            }`}
          />
        )}
        <Title heading='Geschwister' value={getSiblingsInOrder()} />

        <CVAcademicHistory role={ACADEMIC} data={getSortedAcademicHistory()} />

        <CVAcademicHistory role={LANGUAGES} data={languages} />

        <CVAcademicHistory role={HOBBIES} data={hobbies} />

        <CVAcademicHistory role={TRIAL} data={sortedTrials} />

        <CVAcademicHistory
          role={REFERENCES}
          data={
            personalData?.references?.length > 2
              ? [personalData?.references[0], personalData?.references[1]]
              : personalData?.references
          }
          trial={sortedTrials}
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: CalibriRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: CalibriBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
});

export const styles = StyleSheet.create({
  body: {
    marginTop: '1.75cm',
    marginHorizontal: '2.5cm',
    fontFamily: 'Calibri',
  },
  userImage: {
    width: 90,
    position: 'absolute',
    right: 145,
    top: 50,
  },
  title: {
    fontSize: 20,
    marginBottom: 25,
    fontWeight: 'bold',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2,
  },
  heading: {
    fontSize: 12,
    width: 130,
    fontWeight: 'bold',
  },
  heading_value: {
    fontSize: 12,
  },
  linebreak: {
    width: '100%',
    border: '0.5pt solid #bfbfbf',
    marginTop: 30,
    marginBottom: 21,
  },
});

export default CVGenerator;
