export const ROUTES = (props) => {
  const userId = props?.userId || undefined;
  const diaryId = props?.diaryId;
  const jobId = props?.jobId || undefined;
  const jobCategory = props?.jobCategory || null;
  const jobType = props?.jobType || null;
  const classId = props?.classId || undefined;
  const letterId = props?.letterId;

  return {
    home: '/',
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    dashboard: '/dashboard',
    about: '/about',
    impressum: '/impressum',
    termsOfUse: '/terms-of-use',
    agb: '/agb',
    student: {
      options: `/${userId}`,
      dashboard: `/${userId}/dashboard`,
      diary: `/${userId}/diary`,
      motivationLetterLibrary: `/${userId}/motivation-letter-library`,
      documents: `/${userId}/documents`,
      languages: `/${userId}/languages`,
      academic: `/${userId}/academic`,
      hobbies: `/${userId}/hobbies`,
      trial: `/${userId}/trial`,
      personalData: `/${userId}/personal-data`,
      job: {
        all: `/jobs`,
        categoryOrTypeJobs: `/jobs?category=${encodeURIComponent(jobCategory)}&type=${encodeURIComponent(jobType)}`,
        jobId: `/jobs/${jobId}`,
      },
      edit: {
        diary: `/${userId}/edit/diary`,
        diaryById: `/${userId}/edit/diary?index=${diaryId}`,
        languages: `/${userId}/edit/languages`,
        academic: `/${userId}/edit/academic`,
        hobbies: `/${userId}/edit/hobbies`,
        trial: `/${userId}/edit/trial`,
        personalInfo: `/${userId}/edit/personal`,
        documents: `/${userId}/edit/documents`,
        references: `/${userId}/edit/references`,
        motivationLetter: `/${userId}/edit/motivation-letter`,
        motivationLetterById: `/${userId}/edit/motivation-letter?id=${letterId}`,
      },
    },
    teacher: {
      classes: {
        all: `/${userId}/classes`,
        overview: `/${userId}/classes/${classId}`,
        editClassById: `/${userId}classes/${classId}`,
        invite: '/invite/',
        add: {
          students: `/${userId}/classes/${classId}/add/students`,
          teachers: `/${userId}/classes/${classId}/add/teachers`,
        },
      },
      students: `/${userId}/students`,
      teachers: `/${userId}/teachers`,
      settings: `/${userId}/settings`,
    },
    admin: {
      dashboard: `/${userId}/admin/dashboard`,
      job: {
        allJobs: `/${userId}/admin/job`,
        addJob: `/${userId}/admin/job/add`,
        editJobById: `/${userId}/admin/job/update/${jobId}`,
      },
      teachers: `/${userId}/admin/teachers`,
      students: `/${userId}/admin/students`,
    },
  };
};
