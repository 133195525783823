import React, { useState } from 'react'
import { Box, Button as MUIButton, Skeleton, Tooltip } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'

import userImage from 'assets/images/blank_user.png'
import PlayIcon from 'assets/icons/play'
import { API_URL, BACKEND_URL, FRONTEND_URL } from 'utils/apiConstants'
import Documents from './Documents'
import CustomVideoPlayerModal from 'components/CustomVideoPlayerModal'
import ShareProfileIcon from 'assets/icons/shareProfile'
import { ShowToast } from 'utils/toastMessages'
import ShareProfileModal from './ShareProfileModal'
import DownloadDocuments from './DownloadDocuments'
import { FileUploader } from 'react-drag-drop-files'
import SmallEditIcon from 'assets/icons/smallEdit'
import { translation } from 'utils/germanTranslation'


const VIDEO_NOT_AVAILABLE_TOOLTIP = 'Video nicht verfügbar'
const UPLOAD_VIDEO_IN_PROFILE_TOOLTIP =
  'Klicke rechts neben „Infos über mich“ auf den Stift und lade dort dein Video über dich hoch!'

const StudentIntro = ({
  userId,
  user,
  handleFile,
  loading,
  setToggleProfile,
  canEdit,
  loggedInUser,
  languages,
  hobbies,
  academicHistory,
  trial,
  personalData,
}) => {
  const userName = `${user?.firstName || 'FirstName'} ${
    user?.lastName || 'LastName'
  }`
  const profileUrl = `${FRONTEND_URL}/watch-profile/${loggedInUser?.id}`
  const InitialShareProfileModalConfig = {
    show: false,
    toShareEmail: '',
    loading: false,
    showChangeEmailTextModal: false,
    emailText: [
      `<h1><span style="font-size:16px"><strong>Guten Tag,</strong></span></h1>
      <p><span style="font-size:16px">${userName} möchte sein Lehrstell.ch-Bewerbungsprofil teilen. Durch Klicken auf den folgenden Link müssen Sie Ihre Identität bestätigen. Wenn Sie auf „bestätigen“ klicken, erscheint ein Feld wo Sie einen Zugangscode eingeben können, den Sie gerade in einer E-Mail erhalten haben (Spam/Junk-Mail prüfen).</span></p>
      <p><span style="font-size:16px">Mit diesem Code haben Sie 12 Monate lang Zugriff auf dieses Profil.
      Sie können diesen Link teilen, wenn ein Mitarbeiter ebenfalls Zugriff auf dieses Profil benötigt. Er muss seine Identität verifizieren und erhält dann auch Zugang.</span></p>`,
      `<p><span style="font-size:16px">Link zum Profil von&nbsp;<strong>${userName}</strong>:&nbsp;</span></p>`,
      `<p><a href="${profileUrl}" style="font-size: 16px;" target="_blank">${profileUrl}</a></p>`,
      `<p style="font-size:16px; font-weight: bold">Wichtig: Der Zugriff auf das Profil erfolgt immer über diesen Link (nur diesen Link) – bitte löschen Sie diese E-Mail nicht, wenn Sie noch einmal darauf zugreifen möchten.</p>`,
    ],
    isCVAttached: false,
    isMotivationLetterAttached: '',
    documentsAttached: [],
    uploadedDocument: null,
  }

  const [showVideoModal, setShowVideoModal] = useState(false)
  const [shareProfileModalConfig, setShareProfileModalConfig] = useState(
    InitialShareProfileModalConfig
  )
  const [documents, setDocuments] = useState([])

  const sendProfileAccess = async (e) => {
    e.preventDefault()
    setShareProfileModalConfig({ ...shareProfileModalConfig, loading: true })
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.student.shareProfile + user?.id,
        {
          toShareEmail: shareProfileModalConfig.toShareEmail,
          emailText: shareProfileModalConfig.emailText,
          isCVAttached: shareProfileModalConfig.isCVAttached,
          isMotivationLetterAttached:
            shareProfileModalConfig.isMotivationLetterAttached,
          documentsAttached: shareProfileModalConfig.documentsAttached,
          uploadedDocument: shareProfileModalConfig.uploadedDocument,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data?.success) {
        ShowToast({ type: 'success', message: data?.message })
        setShareProfileModalConfig(InitialShareProfileModalConfig)
      }
    } catch (error) {
      ShowToast({ message: error })
      setShareProfileModalConfig(InitialShareProfileModalConfig)
    }
  }

  const isVideoAvailable = _.isEmpty(user?.aboutMeVideo)
  return (
    <Box className='flex flex-col sm:flex-row md:flex-col lg:flex-row items-center gap-4 text-softBlack'>
      <div className='relative flex-shrink-0'>
        {loading ? (
          <Skeleton
            variant='circular'
            className='w-[162px] h-[162px] sm:w-[120px] sm:h-[120px] 2xl:w-[140px] 2xl:h-[140px] -z-[11] object-cover rounded-full border-8 border-white'
          />
        ) : (
          <>
            <FileUploader
              handleChange={handleFile}
              types={['jpeg', 'png', 'jpg']}
            >
              <img
                src={user?.avatar ? `${BACKEND_URL}/${user.avatar}` : userImage}
                className='w-[162px] h-[162px] sm:w-[120px] sm:h-[120px] 2xl:w-[140px] 2xl:h-[140px] -z-[11] object-cover rounded-full border-8 border-white'
                alt=''
              />
              {canEdit && (
                <div className='absolute top-[10px] right-[20px] border-2 border-[#E8E8E8] rounded-full'>
                  <SmallEditIcon />
                </div>
              )}
            </FileUploader>
          </>
        )}
      </div>
      <Box className='flex flex-col gap-6 sm:gap-2 2xl:gap-3 w-full'>
        <div className='flex flex-col lg:flex-row items-center sm:items-start lg:items-center gap-2 lg:gap-2'>
          <p className='text-3xl sm:text-lg 2xl:text-2xl font-extrabold lg:text-left'>
            {user?.firstName} {user?.lastName}
          </p>
          <div className='flex items-center gap-3'>
            <Tooltip
              title={
                !canEdit
                  ? 'Es wurde noch kein Video hochgeladen!'
                  : isVideoAvailable
                  ? userId === loggedInUser?.id
                    ? UPLOAD_VIDEO_IN_PROFILE_TOOLTIP
                    : VIDEO_NOT_AVAILABLE_TOOLTIP
                  : ''
              }
              arrow
            >
              <span>
                <MUIButton
                  onClick={() => setShowVideoModal(true)}
                  disabled={isVideoAvailable}
                  startIcon={<PlayIcon disabled={isVideoAvailable} />}
                >
                  <p
                    className={`${
                      isVideoAvailable
                        ? 'text-black opacity-60'
                        : 'text-[#FF0100]'
                    } text-base sm:text-xs 2xl:text-base normal-case`}
                  >
                    Mein Video
                  </p>
                </MUIButton>
              </span>
            </Tooltip>
            <button
              className='block md:hidden bg-sky-300 rounded-xl px-3 font-medium'
              onClick={() => setToggleProfile(true)}
            >
              {translation.personalInformation}
            </button>
          </div>
          <DownloadDocuments user={user} />
        </div>
        <div className='flex justify-between items-center flex-wrap gap-3'>
          <Documents
            userId={userId}
            canEdit={canEdit}
            setDocuments={setDocuments}
            languages={languages}
            hobbies={hobbies}
            academicHistory={academicHistory}
            trial={trial}
            personalData={personalData}
            profilePicture={user?.avatar || ''}
          />
          {canEdit && (
            <div
              onClick={() =>
                setShareProfileModalConfig({
                  ...shareProfileModalConfig,
                  show: true,
                })
              }
              className='bg-[#458EFF] py-3 px-4 sm:py-2 text-white rounded-2xl flex gap-2 items-center justify-center cursor-pointer'
            >
              <ShareProfileIcon />
              <h3 className='text-base sm:text-xs '>Profil teilen</h3>
            </div>
          )}
        </div>
      </Box>
      <ShareProfileModal
        documents={documents}
        value={shareProfileModalConfig}
        setValue={setShareProfileModalConfig}
        initialValue={InitialShareProfileModalConfig}
        onSubmit={sendProfileAccess}
        userId={userId}
        user={loggedInUser}
      />
      <CustomVideoPlayerModal
        open={showVideoModal}
        handleClose={() => setShowVideoModal(false)}
        videoURL={BACKEND_URL + '/' + user?.aboutMeVideo?.path}
      />
    </Box>
  )
}

export default StudentIntro
