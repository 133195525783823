import Button from 'components/Button'
import CustomInput from 'components/Input'
import React from 'react'
import { EditSchool } from '.'

const ChangeSchoolDetailModal = ({
  value,
  setValue,
  searchParams,
  setSearchParams,
  onSubmit,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <div className='grid grid-cols-none sm:grid-cols-3 gap-3'>
        <CustomInput
          label='School Name'
          value={value?.schoolName}
          handleChange={(newValue) =>
            setValue({ ...value, schoolName: newValue })
          }
        />
        <CustomInput
          label='ZipCode'
          value={value?.schoolZipCode}
          handleChange={(newValue) =>
            setValue({ ...value, schoolZipCode: newValue })
          }
        />
        <CustomInput
          label='Place'
          value={value?.schoolPlace}
          handleChange={(newValue) =>
            setValue({ ...value, schoolPlace: newValue })
          }
        />
      </div>
      <div className='flex justify-center gap-4 mt-4'>
        <Button
          title='Cancel'
          onClick={() =>
            setSearchParams({ ...searchParams, [EditSchool]: 'false' })
          }
          containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
          backgroundColor='#E9F1FC'
        />
        <Button
          title='Update'
          type='submit'
          // onClick={() => handleAddSecondAdmin()}
          containerClasses='px-8 py-2 rounded-lg text-white'
          backgroundColor='#458EFF'
        />
      </div>
    </form>
  )
}

export default ChangeSchoolDetailModal
