// import './styles.css';
import { CircularProgress } from '@mui/material'
import { NoTrialCompleted } from 'components/CVGenerator'
import { Paragraph, Document, Packer, TextRun, ImageRun } from 'docx'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import moment from 'moment'
import { getSiblingsInOrder, getSortedAcademicHistory } from 'utils'
import { CV } from 'utils/documentTypes'
import { capitalize } from '../Documents'

const GenerateWordCV = ({
  personalData = {},
  academicHistory = [],
  hobbies,
  trial = [],
  languages,
  profilePicture,
  referencesData,
  loading,
}) => {
  const siblings = getSiblingsInOrder(personalData)
  const sortedEducation = getSortedAcademicHistory(academicHistory)

  const AcademicHistoryTemplate = (sortedEducation) => {
    if (sortedEducation.length !== 0) {
      let temp = [LineBreak(), ...title('Schulbildung')]
      sortedEducation.forEach((item) => {
        temp.push(
          ...title(
            `${item.startingYear} - ${item.endingYear}`,
            `${item.schoolName}, ${item.place}`,
            true,
            false
          )
        )
      })
      return temp
    }
    return []
  }
  const title = (heading = '', value = '', tabs = true, bold = true) => {
    let tempHeading = `${heading}${tabs ? '\t\t' : ''}`
    let result = [
      new TextRun({
        text: tempHeading,
        bold,
        break: 1,
        font: 'Calibri',
        size: 24,
      }),
      new TextRun({
        text: value,
        font: 'Calibri',
        size: 24,
      }),
    ]

    return result
  }

  const LineBreak = () =>
    new TextRun({
      text: `\t\t\t\t\t\t\t\t\t\t\t\t        `,
      bold: true,
      break: 1,
      underline: {
        color: '000000',
        type: 'single',
      },
    })

  const LanguagesTemplate = () => {
    if (languages.length !== 0) {
      let temp = [LineBreak(), ...title('Sprachen')]
      languages.forEach((item) => {
        temp.push(
          ...title(
            `${
              item.language === 'German'
                ? `${item.language}`
                : `${item.language}\t`
            }`,
            `${item.proficiency}`,
            true,
            false
          )
        )
      })
      return temp
    }
    return []
  }

  const HobbiesTemplate = () => {
    if (hobbies.length !== 0) {
      let temp = [LineBreak(), ...title('Hobbies')]
      hobbies.forEach((item) => {
        temp.push(
          ...title(`${item.title}\t`, `${item.description}`, true, false)
        )
      })
      return temp
    }
    return []
  }

  const TrialTemplate = () => {
    let temp = [LineBreak(), ...title('Schnupperlehren')]
    let anyTrialCompleted = false
    if (trial.length !== 0) {
      for (let index = 0; index < trial.length; index++) {
        if (moment(trial[index]?.date?.to).isSameOrBefore(moment(new Date()))) {
          anyTrialCompleted = true
          break
        }
      }
      if (anyTrialCompleted) {
        trial.forEach((item) => {
          temp.push(
            ...title(
              `${moment(item?.date?.from).format('DD.MM')} - ${moment(
                item?.date?.to
              ).format('DD.MM.YYYY')}\t`,
              [item.job, item.companyName, item.place]
                .filter(Boolean)
                .join(', '),
              false,
              false
            )
          )
        })
        return temp
      }
    }
    return [...temp, ...title(NoTrialCompleted, '', false, false)]
  }

  const ReferencesTemplate = () => {
    if (referencesData.length !== 0) {
      let temp = [LineBreak(), ...title('Referenzen')]
      referencesData.forEach((item, index) => {
        temp.push(
          ...title(
            `${item.firstName} ${item.lastName} ${
              item.designation ? '(' + item.designation + ')' : ''
            }`,
            '',
            false,
            false
          )
        )
        temp.push(...title(`Telefon ${item.contactNumber}`, '', false, false))
        temp.push(...title(`${item.email}`, '', false, false))
        temp.push(
          new TextRun({
            text: '',
            break: 1,
          })
        )
      })
      return temp
    }
    return []
  }

  const IntroductionTemplate = () => {
    let temp = []

    const userAddress1 = `${
      personalData?.address && (personalData?.postalCode || personalData?.place)
        ? personalData?.address + ' '
        : personalData?.address
    }`
    const userAddress2 = `${
      personalData?.postalCode && personalData?.place
        ? personalData?.postalCode + ' '
        : personalData?.postalCode
    }${personalData?.place && personalData?.place}`

    if (personalData.hasOwnProperty('firstName'))
      temp.push(...title('Vorname', personalData.firstName))

    if (personalData.hasOwnProperty('lastName'))
      temp.push(...title('Nachname', personalData.lastName))

    if (userAddress1) temp.push(...title('Adresse', userAddress1))
    if (userAddress2) temp.push(...title('\t', userAddress2))

    if (personalData.hasOwnProperty('phone'))
      temp.push(...title('Telefon', personalData.phone))

    if (personalData.hasOwnProperty('email'))
      temp.push(...title('Email\t', personalData.email))

    if (personalData.hasOwnProperty('nationality'))
      temp.push(...title('Nationalität', personalData.nationality))

    if (personalData.hasOwnProperty('dateOfBirth'))
      temp.push(
        ...title(
          'Geburtsdatum\t',
          personalData.dateOfBirth
            ? `${
                moment(personalData.dateOfBirth).date() <= 9
                  ? `0${moment(personalData.dateOfBirth).date()}`
                  : moment(personalData.dateOfBirth).date()
              }. ${moment(personalData.dateOfBirth).format('MMMM')} ${moment(
                personalData.dateOfBirth
              ).year()}`
            : '',
          false
        )
      )

    if (
      personalData.hasOwnProperty('fatherFirstName') &&
      personalData.hasOwnProperty('fatherLastName') &&
      personalData.hasOwnProperty('fatherOccupation') &&
      (!_.isEmpty(personalData.fatherFirstName) ||
        !_.isEmpty(personalData.fatherLastName))
    ) {
      temp.push(
        ...title(
          'Eltern\t',
          `${personalData.fatherFirstName} ${personalData.fatherLastName} ${
            !_.isEmpty(personalData.fatherOccupation)
              ? '(' + personalData.fatherOccupation + ')'
              : ''
          }`
        )
      )
    }

    if (
      personalData.hasOwnProperty('motherFirstName') &&
      personalData.hasOwnProperty('motherLastName') &&
      personalData.hasOwnProperty('motherOccupation') &&
      (!_.isEmpty(personalData.motherFirstName) ||
        !_.isEmpty(personalData.motherLastName))
    ) {
      temp.push(
        ...title(
          `${
            _.isEmpty(personalData.fatherFirstName) &&
            _.isEmpty(personalData.fatherLastName)
              ? 'Eltern\t'
              : '\t'
          }`,
          `${personalData.motherFirstName} ${personalData.motherLastName} ${
            !_.isEmpty(personalData.motherOccupation)
              ? '(' + personalData.motherOccupation + ')'
              : ''
          }`
        )
      )
    }

    if (siblings !== '') temp.push(...title('Geschwister', siblings))

    return temp
  }

  const PictureTemplate = () => {
    if (profilePicture !== '') {
      return [
        new ImageRun({
          data: profilePicture,
          transformation: {
            width: 130,
            height: 170,
          },
          floating: {
            horizontalPosition: {
              offset: 5150000,
            },
            verticalPosition: {
              offset: 1350000,
            },
          },
        }),
      ]
    }
    return []
  }

  const generate = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Lebenslauf',
                  bold: true,
                  font: 'Calibri',
                  size: 32,
                }),
                new TextRun({
                  break: 1,
                  text: '',
                }),
                ...PictureTemplate(),
                ...IntroductionTemplate(),
                ...AcademicHistoryTemplate(sortedEducation),
                ...LanguagesTemplate(),
                ...HobbiesTemplate(),
                ...TrialTemplate(),
                ...ReferencesTemplate(),
              ],
            }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => {
      // console.log(blob);
      saveAs(
        blob,
        `${capitalize(CV)}_${personalData.firstName} ${
          personalData.lastName
        }.docx`
      )
      // console.log('Document created successfully');
    })
  }

  return (
    <li className='list-disc list-outside my-1 no-underline hover:underline underline-offset-1 hover:text-blue-700 cursor-pointer transition-all ease-linear'>
      {loading ? (
        <CircularProgress />
      ) : (
        <p onClick={generate}>Lebenslauf als Word herunterladen</p>
      )}
    </li>
  )
}

export default GenerateWordCV
