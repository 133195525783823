import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { user } from 'store/reducers/user'
import { useCallback } from 'react'
import { ROUTES } from 'utils/routes'

export const SHARE_PROFILE = 'SHARE PROFILE'

const VerifyEditAccess = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const loggedInUser = useSelector(user)
  const [access, setAccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userExist, setUserExist] = useState(false)
  const [privateProfile, setPrivateProfile] = useState(false)
  const { id } = useParams()
  const [decodedUserId, setDecodedUserId] = useState(id)
  const [isClassTeacher, setIsClassTeacher] = useState(false)

  const verifyAccess = useCallback(async () => {
    let decodedToken = null
    let isProfilePrivate = true
    let userFromShareProfile = false
    try {
      decodedToken = jwtDecode(id)
      // if (loggedInUser?.email === decodedToken.requesterEmail) {
      setDecodedUserId(decodedToken?.originalUserId)
      isProfilePrivate = false
      userFromShareProfile = true
      setUserExist(true)
      setLoading(false)
      // }
    } catch (error) {
      // ShowToast({ message: error });
    }
    if (!userFromShareProfile) {
      const user = await axios.get(
        BACKEND_URL + API_URL.user.findById + decodedUserId
      )
      if (user?.data?.success) {
        setUserExist(true)
        const { data } = await axios.get(BACKEND_URL + API_URL.verifyAccess, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            id: decodedToken ? decodedToken.id : decodedUserId,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        })
        if (data.success) {
          setAccess(true)
        } else {
          if (!isProfilePrivate) {
            setPrivateProfile(false)
          } else {
            setPrivateProfile(true)
          }
          setAccess(false)
        }
        if (data?.role === 'Teacher') {
          setIsClassTeacher(true)
        }
        setLoading(false)
      } else {
        setUserExist(false)
        setLoading(false)
      }
    }
  }, [decodedUserId, id])

  useEffect(() => {
    if (!loggedInUser?.id && !location?.state?.fromShareProfile) {
      navigate('/login')
    } else if (String(loggedInUser?.role).toLocaleLowerCase() === 'admin') {
      navigate(ROUTES({ userId: loggedInUser?.id }).admin.dashboard)
    } else {
      verifyAccess()
    }
  }, [id, loggedInUser, navigate, verifyAccess])

  return (
    <>
      {loading ? (
        <div className='flex items-center justify-center h-screen'>
          <CircularProgress />
        </div>
      ) : userExist ? (
        privateProfile ? (
          'The profile is private'
        ) : (
          children(decodedUserId, access, navigate, isClassTeacher)
        )
      ) : (
        `User doesn't exist`
      )}
    </>
  )
}

export default VerifyEditAccess
