import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import AddMotivationLetter from 'components/Student/EditMotivationLetter'
import EditAcademicHistory from 'components/Student/EditAcademicHistory'
import AddStudentsToClass from 'components/Teacher/Classes/AddStudents'
import EditPersonalDetail from 'components/Student/EditPersonalDetail'
import MotivationLetter from 'components/Student/MotivationLetter'
import ClassDetails from 'components/Teacher/Classes/ClassDetails'
import AccountSettings from 'components/Teacher/Classes/Settings'
import StudentOptions from 'components/Student/StudentOptions'
import EditDocuments from 'components/Student/EditDocuments'
import StudentDashboard from 'components/Student/Dashboard'
import StudentsAndTeachers from 'components/Admin/Teachers'
import EditLanguage from 'components/Student/EditLanguage'
import EditReferences from 'components/Student/References'
import AddClass from 'components/Teacher/Classes/AddClass'
import 'react-image-gallery/styles/css/image-gallery.css'
import EditHobbies from 'components/Student/EditHobbies'
import AdminDashboard from 'components/Admin/Dashboard'
import TermsOfUse from 'components/Footer/TermsOfUse'
import JobDetail from 'components/Student/JobDetail'
import ResetPassword from 'components/ResetPassword'
import EditTrial from 'components/Student/EditTrial'
import Impressum from 'components/Footer/Impressum'
import AddDiary from 'components/Student/EditDiary'
import UpdateJob from 'components/Admin/Job/Update'
import WatchProfile from 'components/WatchProfile'
import StudentJobs from 'components/Student/Jobs'
import ExportDiary from 'components/ExportDiary'
import AddJob from 'components/Student/Jobs/Add'
import LandingPage from 'components/LandingPage'
import Classes from 'components/Teacher/Classes'
import AboutUs from 'components/Footer/AboutUs'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from 'components/Navbar/Navbar'
import Diary from 'components/Student/Diary'
import AllJobs from 'components/Admin/Job'
import { user } from 'store/reducers/user'
import AGB from 'components/Footer/AGB'
import Footer from 'components/Footer'
import Invite from 'components/Invite'
import Test from 'components/Test'
import 'assets/css/App.css'
import { translation } from 'utils/germanTranslation'
import Error404 from 'components/Error404'

const PrivateRoute = () => {
  const auth = useSelector(user).id

  return auth ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' />
  )
}

const ProtectedTeacherRoute = () => {
  const { role } = useSelector(user)
  const navigate = useNavigate()
  const [isTeacher, setIsTeacher] = useState(false)

  useEffect(() => {
    if (String(role).toLowerCase() === 'teacher') {
      setIsTeacher(true)
    } else {
      navigate(-1)
    }
  }, [role, navigate])

  return (
    isTeacher && (
      <>
        <Outlet />
      </>
    )
  )
}

const ProtectedAdminRoute = () => {
  const { role } = useSelector(user)
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (String(role).toLowerCase() === 'admin') {
      setIsAdmin(true)
    } else {
      navigate(-1)
    }
  }, [role, navigate])

  return (
    isAdmin && (
      <>
        <Outlet />
      </>
    )
  )
}

const App = () => {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <div className='min-h-screen h-full'>
          <Routes>
            <Route index element={<LandingPage isRegister={true} />} />
            <Route path='/404' element={<Error404 />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/impressum' element={<Impressum />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/agb' element={<AGB />} />
            <Route path='/login' element={<LandingPage isRegister={false} />} />
            <Route path='/test' element={<Test />} />
            <Route path='/export-diary' element={<ExportDiary />} />
            <Route path='/invite/:token' element={<Invite />} />
            <Route path='/watch-profile/:userId' element={<WatchProfile />} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            <Route path='/jobs'>
              <Route index element={<StudentJobs />} />
              <Route path=':jobId' element={<JobDetail />} />
            </Route>
            <Route path='/:id/dashboard' element={<StudentDashboard />} />
            <Route path='/:id/' element={<PrivateRoute />}>
              <Route index element={<StudentOptions />} />
              <Route path='admin' element={<ProtectedAdminRoute />}>
                <Route path='dashboard' element={<AdminDashboard />} />
                <Route path='job'>
                  <Route index element={<AllJobs />} />
                  <Route
                    path='add'
                    element={<AddJob title={`Job ${translation.add}`} />}
                  />
                  <Route path='update/:jobId' element={<UpdateJob />} />
                </Route>
                <Route
                  path='teachers'
                  element={<StudentsAndTeachers role='Teachers' />}
                />
                <Route
                  path='students'
                  element={<StudentsAndTeachers role='Students' />}
                />
              </Route>
              <Route path='diary' element={<Diary />} />
              <Route
                path='motivation-letter-library'
                element={<MotivationLetter />}
              />
              <Route path='classes' element={<ProtectedTeacherRoute />}>
                <Route index element={<Classes />} />
                <Route path='add' element={<AddClass />} />
                <Route path=':classId'>
                  <Route index element={<ClassDetails />} />
                  <Route path='add'>
                    <Route
                      path='students'
                      element={<AddStudentsToClass role='Student' />}
                    />
                    <Route
                      path='teachers'
                      element={<AddStudentsToClass role='Teacher' />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path='settings'>
                <Route index element={<AccountSettings />} />
              </Route>
              <Route path='edit'>
                <Route path='academic' element={<EditAcademicHistory />} />
                <Route path='personal' element={<EditPersonalDetail />} />
                <Route path='references' element={<EditReferences />} />
                <Route path='documents' element={<EditDocuments />} />
                <Route path='languages' element={<EditLanguage />} />
                <Route path='hobbies' element={<EditHobbies />} />
                <Route path='diary' element={<AddDiary />} />
                <Route
                  path='motivation-letter'
                  element={<AddMotivationLetter />}
                />
                <Route path='trial' element={<EditTrial />} />
              </Route>
            </Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
