import React, { useRef } from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, user as userReducer } from 'store/reducers/user'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import userImage from 'assets/images/blank_user.png'
import CustomImageReplacer from 'components/CustomImageReplacer'
import axios from 'axios'
import CustomInput from 'components/Input'
import { useState } from 'react'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import CustomImageCropModal from 'components/CustomImageCropModal'
import { ShowToast } from 'utils/toastMessages'
import { translation } from 'utils/germanTranslation'
import { Navigate } from 'react-router-dom'

const AccountSettings = () => {
  const user = useSelector(userReducer)

  const toastId = useRef(null)
  const previewCanvasRef = useRef(null)

  // State
  const [showCropModal, setShowCropModal] = useState(false)
  const [crop, setCrop] = useState()
  const [imgSrc, setImgSrc] = useState('')
  const [loadingImage, setLoadingImage] = useState(false)
  const dispatch = useDispatch()

  const handleFile = (file) => {
    setLoadingImage(true)
    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '')
      setLoadingImage(false)
    })
    setShowCropModal(true)
    reader.readAsDataURL(file)
  }

  const initialState = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  const [value, setValue] = useState(initialState)

  const handleSubmit = async () => {
    try {
      const { data } = await axios.put(
        BACKEND_URL + API_URL.teacher.updateSettings,
        value,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data.success) {
        toast['success'](data?.message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        })
      }
      dispatch(fetchUser())
    } catch (error) {
      toast['error'](error?.response?.data?.message || error?.message, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      })
      dispatch(fetchUser())
    }
  }

  const handleValueChange = (newValue, field) => {
    setValue({
      ...value,
      [field]: newValue,
    })
  }

  const handleUploadUserImage = async () => {
    const dataUrl = previewCanvasRef.current.toDataURL('image/jpeg')
    const blob = await fetch(dataUrl).then((r) => r.blob())
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
    const formData = new FormData()
    formData.append('photo', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
        id: user.id,
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: false })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
            autoClose: false,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
          })
          toastId.current = null
        }
      },
    }
    setShowCropModal(false)
    axios
      .post(BACKEND_URL + API_URL.user.upload, formData, config)
      .then((res) => {
        dispatch(fetchUser())
      })
      .catch((error) => {
        ShowToast({ error })
      })
  }
  if (user.role.toLowerCase() === 'admin') {
    return <Navigate to='/404' />
  }
  return (
    <div className='py-10 bg-softWhite min-h-screen flex justify-center px-6 xl:px-0'>
      <div className='max-w-[1166px] w-full text-softBlack'>
        <h1 className='font-bold text-xl sm:text-3xl pb-6'>
          Account-Einstellungen
        </h1>
        <div className='bg-white rounded-2xl p-6'>
          {user.role === 'Teacher' && (
            <div className='flex-shrink-0 w-full sm:w-auto max-w-none sm:max-w-[165px] flex items-center justify-center'>
              <div className='w-[162px] h-[162px] relative'>
                <img
                  src={
                    user?.avatar ? `${BACKEND_URL}/${user.avatar}` : userImage
                  }
                  className='object-cover rounded-full border-8 border-white w-[162px] h-[162px]'
                  alt=''
                />
                <CustomImageReplacer
                  handleChange={handleFile}
                  showCropModal={showCropModal}
                  setShowCropModal={setShowCropModal}
                />
              </div>
            </div>
          )}
          <div
            className={`flex gap-6 flex-col max-w-[547px] w-full ${
              user.role === 'Teacher' ? 'mt-8' : 'mt-0'
            }`}
          >
            {user.role === 'Teacher' && (
              <>
                <CustomInput
                  label={translation.firstName}
                  value={value.firstName}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'firstName')
                  }
                />
                <CustomInput
                  label={translation.lastName}
                  value={value.lastName}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'lastName')
                  }
                />
                <CustomInput
                  label='Email ID'
                  value={value.email}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'email')
                  }
                />
              </>
            )}
            <Typography className='font-bold text-xl sm:text-2xl'>
              Passwort ändern
            </Typography>
            <CustomInput
              label='Altes Passwort'
              value={value.oldPassword}
              handleChange={(newValue) =>
                handleValueChange(newValue, 'oldPassword')
              }
              type='password'
            />
            <CustomInput
              label='Neues Passwort eingeben'
              value={value.newPassword}
              handleChange={(newValue) =>
                handleValueChange(newValue, 'newPassword')
              }
              type='password'
            />
            <CustomInput
              label='Neues Passwort erneut eingeben'
              value={value.confirmNewPassword}
              handleChange={(newValue) =>
                handleValueChange(newValue, 'confirmNewPassword')
              }
              type='password'
            />
          </div>
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-end gap-3 mt-6'>
          <Button
            title={translation.update}
            onClick={handleSubmit}
            titleClasses='text-white text-lg font-semibold'
            containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#458EFF]'
            // disabled={disabled}
          />
        </div>
      </div>
      <CustomImageCropModal
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        crop={crop}
        setCrop={setCrop}
        handleUploadUserImage={handleUploadUserImage}
        previewCanvasRef={previewCanvasRef}
        closeCropModal={() => setShowCropModal(false)}
        loadingImage={loadingImage}
        setShowCropModal={setShowCropModal}
        showCropModal={showCropModal}
      />
    </div>
  )
}

export default AccountSettings
