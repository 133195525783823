import HomeIcon from 'assets/icons/home'
import logo from 'assets/images/logo.png'
import NavbarProfile from './Profile'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, user } from 'store/reducers/user'
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import { ROUTES } from 'utils/routes'
import {
  AdminDashboardIcon,
  AdminJobsIcon,
  AdminStudentsIcon,
  AdminTeachersIcon,
} from 'assets/icons/adminRoutes'
import TeacherClasses from 'assets/icons/classes'
import { translation } from 'utils/germanTranslation'
import axios from 'axios'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'
import CustomModal from 'components/CustomModal'
import CustomButton from 'components/Button'
import { useState } from 'react'

const drawerWidth = 240

const getAdminRoutes = (userId) => {
  return {
    right: [
      {
        title: translation.dashboard,
        icon: <AdminDashboardIcon />,
        path: ROUTES({ userId }).admin.dashboard,
      },
      {
        title: translation.teacher,
        icon: <AdminTeachersIcon />,
        path: ROUTES({ userId }).admin.teachers,
      },
      {
        title: translation.student,
        icon: <AdminStudentsIcon />,
        path: ROUTES({ userId }).admin.students,
      },
      {
        title: translation.job,
        icon: <AdminJobsIcon />,
        path: ROUTES({ userId }).admin.job.allJobs,
      },
    ],
    left: [],
  }
}

const getTeacherRoutes = (userId) => {
  return {
    right: [
      {
        title: 'Klassen',
        icon: <TeacherClasses classes='w-[27px] h-[27px]' />,
        path: ROUTES({ userId }).teacher.classes.all,
      },
    ],
    left: [],
  }
}

const getStudentRoutes = (userId) => {
  return {
    right: [
      {
        title: 'Home',
        icon: <HomeIcon classes='w-[27px] h-[27px]' />,
        path: '/',
      },
    ],
    left: [
      {
        title: 'Mein Profil',
        path: ROUTES({ userId }).student.dashboard,
      },
      {
        title: 'Mein Lehrstell-Journal',
        path: ROUTES({ userId }).student.diary,
      },
      {
        title: 'Meine Motivationsschreiben',
        path: ROUTES({ userId }).student.motivationLetterLibrary,
      },
      {
        title: 'Informationen über Berufe',
        path: ROUTES({ userId }).student.job.all,
      },
    ],
  }
}
const Navbar = (props) => {
  const loggedInUser = useSelector(user)
  const { window } = props
  const dispatch = useDispatch()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [deleteAccountModal, setDeleteAccountModal] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const currentRoutes =
    loggedInUser?.role?.toLowerCase() === 'admin'
      ? getAdminRoutes(loggedInUser?.id)
      : loggedInUser?.role?.toLowerCase() === 'teacher'
      ? getTeacherRoutes(loggedInUser?.id)
      : loggedInUser?.role?.toLowerCase() === 'student'
      ? getStudentRoutes(loggedInUser?.id)
      : {
          right: [
            {
              title: 'Heim',
              icon: <HomeIcon classes='w-[27px] h-[27px]' />,
              path: '/',
            },
          ],
          left: [],
        }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {currentRoutes['right'].map((route, index) => (
        <ListItem key={index} disablePadding className=''>
          <NavLink
            to={route?.path}
            className={({ isActive }) =>
              `${isActive && 'bg-[#EEC22C]'} h-full w-full`
            }
            end
          >
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={route?.title} />
            </ListItemButton>
          </NavLink>
        </ListItem>
      ))}
      <Divider />
      <List>
        {currentRoutes['left'].map((route, index) => (
          <ListItem key={index} disablePadding className=''>
            <NavLink
              to={route?.path}
              className={({ isActive }) =>
                `${isActive && 'bg-[#EEC22C]'} h-full w-full`
              }
              end
            >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={route?.title} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleDeleteAccount = async () => {
    try {
      const data = await axios.delete(
        BACKEND_URL + API_URL.deleteUser + loggedInUser?.id
      )
      ShowToast({ type: 'success', message: data.message })
      dispatch(logout())
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar component='nav' className='bg-[#FDCE2F]'>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Link to='/'>
              <div className='w-full md:w-auto'>
                <img src={logo} className='h-10 sm:h-auto' alt='logo' />
              </div>
            </Link>
            <div className='w-full items-center flex justify-end gap-4'>
              <Box
                sx={{ display: { xs: 'none', md: 'flex' } }}
                className='justify-between w-full h-16 ml-4'
              >
                {Object.keys(currentRoutes).map((key) => (
                  <div className='flex' key={key}>
                    {currentRoutes[key].map((route, index) => (
                      <div className={`flex items-center gap-1`} key={index}>
                        {key !== 'right' ? (
                          <NavLink to={route.path} rel='noreferrer'>
                            <Button
                              sx={{ height: '100%' }}
                              className='flex gap-2 text-softBlack '
                            >
                              {key === 'right' && route.icon}
                              <p
                                className={`${
                                  key === 'left' &&
                                  'opacity-70 hover:opacity-100'
                                } normal-case font-semibold text-base`}
                              >
                                {route.title}
                              </p>
                            </Button>
                          </NavLink>
                        ) : (
                          <NavLink
                            to={route?.path}
                            className={({ isActive }) =>
                              `${isActive && 'bg-[#EEC22C]'} h-full`
                            }
                          >
                            <Button
                              sx={{ height: '100%' }}
                              className='flex gap-2 text-softBlack '
                            >
                              {key === 'right' && route.icon}
                              <p
                                className={`${
                                  key === 'left' &&
                                  'opacity-70 hover:opacity-100'
                                } normal-case font-semibold text-base`}
                              >
                                {route.title}
                              </p>
                            </Button>
                          </NavLink>
                        )}
                        {key === 'left' &&
                          index !== currentRoutes[key].length - 1 && (
                            <p className='h-8 w-[1px] bg-black'></p>
                          )}
                      </div>
                    ))}
                  </div>
                ))}
              </Box>
              {loggedInUser?.id !== '' ? (
                <NavbarProfile
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  handleDeleteAccount={() => setDeleteAccountModal(true)}
                />
              ) : (
                <>
                  <Link to={ROUTES().login}>
                    <Button>{translation.login}</Button>
                  </Link>
                </>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <Box component='nav'>
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Toolbar />
      </Box>

      {/* Reset Password - Email Verification */}
      <CustomModal
        open={deleteAccountModal}
        handleClose={() => setDeleteAccountModal(false)}
        containerClasses='w-[90%] max-w-[565px]'
      >
        <div className='flex flex-col items-center justify-center gap-4 h-'>
          <div className='text-softBlack font-bold text-lg xs:text-2xl text-center'>
            Möchtest du wirklich dein Profil löschen? Dies kann nicht rückgängig
            gemacht werden!
          </div>
          <div className='flex flex-col sm:flex-row items-center justify-center gap-4 w-full sm:w-auto'>
            <CustomButton
              title={translation.cancel.toLowerCase()}
              containerClasses='rounded-md w-full sm:w-[120px] h-10'
              backgroundColor='#E9F1FC'
              titleClasses='font-semibold text-[#458EFF]'
              onClick={() => setDeleteAccountModal(false)}
            />
            <CustomButton
              title='Profil endgültig löschen'
              containerClasses='bg-[#458EFF] text-white w-full sm:w-[120px] rounded-md h-10'
              // disabled={!user.resetPasswordEmail || loadingResetPassword}
              onClick={() => handleDeleteAccount()}
              type='button'
            />
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default Navbar
