import { InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const CustomSelect = ({
  label,
  labelClasses,
  value,
  handleChange,
  length,
  containerClasses,
  options = [],
  disabled,
}) => {
  return (
    <div className={`w-full ${containerClasses}`}>
      <InputLabel shrink className={labelClasses}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className='w-full h-[44px]'
        disabled={disabled}
      >
        {options.length
          ? options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))
          : Array.from({
              length,
            }).map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
      </Select>
    </div>
  );
};

export default CustomSelect;
