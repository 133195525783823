import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';
import _ from 'lodash';

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
};

const useGetJobById = (jobId) => {
  const [value, setValue] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (_.isEmpty(value) && jobId) {
      getData();
    }
  }, [jobId]);

  const getData = async () => {
    try {
      const { data: job } = await axios.get(
        BACKEND_URL + API_URL.job.findById + jobId,
        config
      );
      const jobDetail = job.job;
      setValue(jobDetail);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.response?.data?.message);
      toast.error(err?.response?.data?.message, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });
    }
  };

  return { loading, data: value, refetch: getData, error };
};

export default useGetJobById;
