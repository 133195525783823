import React from 'react'
import ReactPlayer from 'react-player'
import CustomModal from './CustomModal'
import CloseIcon from '@mui/icons-material/Close'

const CustomVideoPlayerModal = ({ open, handleClose, videoURL }) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      padding='0'
      containerClasses={'rounded w-full'}
    >
      <CloseIcon
        onClick={handleClose}
        className='absolute -top-[12px] xs:-top-[20px] right-0 sm:-right-[20px] rounded-[50%] cursor-pointer bg-red-600 text-white w-7 xs:w-11 h-7 xs:h-11 z-10 hover:scale-110 transition-all shadow-2xl'
      />
      <ReactPlayer
        url={videoURL}
        width='100%'
        controls
        height='100%'
        playing={open}
      />
    </CustomModal>
  )
}

export default CustomVideoPlayerModal
