import React, { useEffect, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import useGetDiary from 'hooks/useGetDiary'
import DiaryRowTemplate from './RowTemplate'
import DetailPageWithTable from './DetailPageWithTable'
import { ROUTES } from 'utils/routes'
import VerifyEditAccess from 'components/VerifyEditAccess'
import NoAccess from 'components/NoAccess'
import CustomModal from 'components/CustomModal'
import Button from 'components/Button'
import { deleteDiaryById } from './deleteDiaryById'
import { useNavigate } from 'react-router-dom'
import Tag from 'components/Tag'
import AddIcon from 'assets/icons/add'
import { options } from '../EditDiary'
import { translation } from 'utils/germanTranslation'
import { pdf } from '@react-pdf/renderer'
import ExportDiary from 'components/ExportDiary'
import { useSelector } from 'react-redux'
import { user } from 'store/reducers/user'

const Diary = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit) => (canEdit ? <VerifiedDiary id={id} /> : <NoAccess />)}
    </VerifyEditAccess>
  )
}

const EXPORT_DIARY_MODAL = 'EXPORT_DIARY_MODAL'
const DELETE_DIARY_MODAL = 'DELETE_DIARY_MODAL'

const initialModalDetails = {
  open: false,
  index: null,
  modalType: '',
}

const TYPE = 'Typ'
const DATE = 'Datum'

const VerifiedDiary = ({ id }) => {
  const [modalDetails, setModalDetails] = useState(initialModalDetails)
  const [value, setValue] = useState([])
  const { loading, data, refetch } = useGetDiary(id)
  const loggedInUser = useSelector(user)

  const navigate = useNavigate()

  useEffect(() => {
    setValue(data)
  }, [data])

  const handleModalClose = () => setModalDetails(initialModalDetails)
  const [sortBy, setSortBy] = useState('')

  const handleSort = (newValue) => setSortBy(newValue)

  useEffect(() => {
    if (sortBy === TYPE) {
      const sortedRow = []
      for (let option = 0; option < options.length; option++) {
        for (let row = 0; row < data.length; row++) {
          if (data[row].type === options[option].title) {
            sortedRow.push(data[row])
          }
        }
      }
      // Extract all the rows that doesn't match any of the options
      const otherTypeRows = [...data].filter((row) => {
        for (let option = 0; option < options.length; option++) {
          if (row.type === options[option].title) {
            return false
          }
        }
        return true
      })
      setValue([...sortedRow, ...otherTypeRows])
    } else if (sortBy === DATE) {
      const sortedRow = [...data].sort((a, b) => {
        return (
          new Date(b.date?.from || b.date) - new Date(a.date?.from || a.date)
        )
      })
      setValue([...sortedRow])
    }
  }, [sortBy, data])

  const generatePDF = async () => {
    let link = document.createElement('a')
    const blob = await pdf(
      <ExportDiary data={data} loggedInUser={loggedInUser} />
    ).toBlob()

    link.href = URL.createObjectURL(blob)

    link.download = 'Mein Lehrstell-Journal'
    link.click()
  }

  const showExportDiaryModal = () =>
    setModalDetails({
      open: true,
      index: undefined,
      modalType: EXPORT_DIARY_MODAL,
    })

  return (
    <>
      <DetailPageWithTable
        pageTitle={translation.testerDiary}
        userId={id}
        loading={loading}
        rows={value}
        sortingOptions={[TYPE, DATE]}
        sortBy={sortBy}
        setSortBy={setSortBy}
        handleSortBy={handleSort}
        setRows={setValue}
        emptyPageMessage='Bisher noch keinen Eintrag hinzugefügt'
        AddButton={
          <Tag
            icon={<AddIcon />}
            showBorder={false}
            titleClasses='text-sm font-semibold'
            title='Eintrag hinzufügen'
            containerClasses='pt-4'
            onClick={() => {
              navigate(ROUTES({ userId: id }).student.edit.diary)
            }}
          />
        }
        buttons={[
          {
            title: 'Eintrag erstellen',
            containerClasses:
              'bg-[#458EFF] w-full md:w-[200px] min-w-[7rem] h-[56px] rounded-md',
            titleClasses: 'text-white',

            link: ROUTES({ userId: id }).student.edit.diary,
          },
          <Button
            title={translation.exportDiary}
            containerClasses='bg-[#E9F1FC] w-full md:w-[200px] min-w-[7rem] h-[56px] rounded-md'
            titleClasses='text-[#458EFF] font-semibold'
            onClick={showExportDiaryModal}
          />,
        ]}
        HeaderTemplate={() => (
          <TableHead className='bg-[#F0F2F9]'>
            <TableRow>
              <TableCell>{translation.job}</TableCell>
              <TableCell style={{ minWidth: '9rem' }}>
                {translation.companyName}
              </TableCell>
              <TableCell
                style={{ minWidth: '9rem' }}
                className='hidden sm:table-cell'
              >
                {translation.contactPerson}
              </TableCell>
              <TableCell
                style={{ minWidth: '9rem' }}
                className='hidden sm:table-cell'
              >
                {translation.contactEmail}
              </TableCell>
              <TableCell style={{ minWidth: '12rem' }}>
                {translation.type}
              </TableCell>
              <TableCell
                style={{ minWidth: '9rem' }}
                className='hidden sm:table-cell'
              >
                {translation.date}
              </TableCell>
              <TableCell
                style={{ minWidth: '9rem' }}
                className='hidden sm:table-cell'
              >
                {translation.notes}
              </TableCell>
              <TableCell style={{ minWidth: '9rem' }}>
                {translation.actions}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        rowTemplate={(row, index) => (
          <DiaryRowTemplate
            key={index}
            row={row}
            index={index}
            userId={id}
            refetch={refetch}
            onDelete={() => {
              setModalDetails({
                open: true,
                index: row?.id,
                modalType: DELETE_DIARY_MODAL,
              })
            }}
          />
        )}
      />
      <CustomModal
        open={modalDetails.open}
        handleClose={handleModalClose}
        containerClasses='w-[90%] sm:w-auto'
      >
        {modalDetails.modalType === DELETE_DIARY_MODAL ? (
          <div>
            <p className='text-lg sm:text-xl font-semibold text-center'>
              Möchten Sie dieses Tagebuch wirklich entfernen?
            </p>
            <div className='flex justify-center gap-4 mt-4'>
              <Button
                title={translation.sure}
                onClick={() => {
                  deleteDiaryById(
                    id,
                    modalDetails.index,
                    handleModalClose,
                    refetch
                  )
                }}
                containerClasses='px-8 py-2 rounded-lg text-white'
                backgroundColor='#458EFF'
              />
              <Button
                title={translation.cancel}
                onClick={handleModalClose}
                containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
                backgroundColor='#E9F1FC'
              />
            </div>
          </div>
        ) : (
          <ul className='px-4'>
            <li
              className='list-disc list-outside my-1 no-underline hover:underline underline-offset-1 hover:text-blue-700 cursor-pointer transition-all ease-linear'
              onClick={generatePDF}
            >
              Mein Lehrstell-Journal als PDF herunterladen
            </li>
          </ul>
        )}
      </CustomModal>
    </>
  )
}

export default Diary
