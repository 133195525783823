import CustomDatePicker from 'components/DatePicker'
import CustomInput from 'components/Input'
import moment from 'moment'
import React from 'react'
import Attachments from './Attachments'
import { TrialApprenticeship } from './Step1'
import { ApprenticeshipQuestions } from './Step2/Questions'
import StepTitle from './StepTitle'
import { translation } from 'utils/germanTranslation'
import { IconButton, Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'

const TotalAppointments = 3
const INFO_TOOLTIP_TEXT = `Falls du den Satz ändern willst, dann kannst du unten rechts auf Motivationsschreiben erstellen klicken und es
danach in deiner Motivationsschreiben-Bibliothek als WORD herunterladen und individuell anpassen.
Anschliessend kannst du das WORD-File als PDF speichern und in deiner Bibliothek oben rechts auf
Motivationsschreiben hochladen klicken.`

const Step3 = ({
  value,
  step,
  setValue,
  handleChange,
  otherAttachments,
  setOtherAttachments,
}) => {
  const handleDateChange = (
    parsedDate,
    enteredValue,
    appointmentNumber,
    occasion
  ) => {
    const date =
      parsedDate !== 'Invalid date'
        ? moment(parsedDate, 'DD/MM/YYYY')
        : moment(enteredValue, 'DD/MM/YYYY')
    if (occasion === 'from' && !value.appointments[appointmentNumber].to) {
      return setValue({
        ...value,
        appointments: {
          ...value.appointments,
          [appointmentNumber]: {
            from: date,
            to: date,
          },
        },
      })
    }
    setValue({
      ...value,
      appointments: {
        ...value.appointments,
        [appointmentNumber]: {
          ...value.appointments[appointmentNumber],
          [occasion]: date,
        },
      },
    })
  }

  return (
    <>
      <StepTitle step={`${step} (${value.type})`} />
      <div className='pt-10'>
        {value?.type === TrialApprenticeship ? (
          <>
            <h1 className='font-bold mt-5 text-softBlack'>
              Welche Termine passen dir für eine Schnupperlehre am besten?
            </h1>
            <div>
              {Array.from({ length: TotalAppointments }).map((_, index) => (
                <div key={index}>
                  <h1 className='font-bold mt-5 '>Termin {index + 1}</h1>

                  <div className='grid grid-cols-none sm:grid-cols-2 gap-4'>
                    <CustomDatePicker
                      label={translation.from}
                      inputFormat='dd/MM/yyyy'
                      value={value.appointments[index + 1].from}
                      handleChange={(...props) =>
                        handleDateChange(...props, index + 1, 'from')
                      }
                    />
                    <CustomDatePicker
                      label={translation.to}
                      inputFormat='dd/MM/yyyy'
                      value={
                        value.appointments[index + 1].to ||
                        value.appointments[index + 1].from
                      }
                      handleChange={(...props) =>
                        handleDateChange(...props, index + 1, 'to')
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <CustomInput
              key={ApprenticeshipQuestions[3].label}
              label={ApprenticeshipQuestions[3].label}
              type='text'
              labelClasses='font-medium text-softBlack text-lg whitespace-normal'
              value={value.questions[ApprenticeshipQuestions[3].label]}
              handleChange={(newValue) =>
                handleChange(
                  newValue,
                  'questions',
                  ApprenticeshipQuestions[3].label
                )
              }
              placeholder={ApprenticeshipQuestions[3].placeholder}
              multiline
              disableInputPadding
              minRows={4}
            />

            <div className='py-6'>
              <h3 className='font-bold'>
                Dieser Satz erscheint automatisch am Schluss deines
                Motivationsschreibens:
              </h3>
              <p className='italic text-sm flex items-center gap-1'>
                (Möchtest du den Satz verändern, gehe rechts auf das Info-Icon)
                <Tooltip title={INFO_TOOLTIP_TEXT}>
                  <IconButton>
                    <Info className='text-xl text-gray-500' />
                  </IconButton>
                </Tooltip>
              </p>

              <p className='pt-6 pb-4 text-gray-600'>
                Ich freue mich, wenn Sie mich zu einem Vorstellungsgespräch
                einladen, damit ich Sie von meiner Persönlichkeit überzeugen
                kann und warte gespannt auf Ihre Antwort.
              </p>
            </div>
          </>
        )}
        <Attachments
          value={value}
          handleChange={handleChange}
          otherAttachments={otherAttachments}
          setOtherAttachments={setOtherAttachments}
        />
      </div>
    </>
  )
}

export default Step3
