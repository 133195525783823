import _ from 'lodash'

export const jobCategories = ['Alle Berufe', 'EBA', 'EFZ']

export const jobTypes = [
  'Bau & Handwerk',
  'Pflege & Soziales',
  'Fahrzeuge',
  'Gastronomie & Hotel',
  'Design & Gestaltung',
  'Essen',
  'Natur',
  'Schönheit',
  'Verkauf',
  'Logistik & Transport',
  'Andere',
]

export const ACADEMIC_HISTORY = 'academicHistory'

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export const NoSiblings = 'Keine Geschwister'

export const getSiblingsInOrder = (personalData) => {
  let siblingsName = ''
  if (!_.isEmpty(personalData)) {
    let siblings = personalData.siblings
    siblings.forEach((item, index) => {
      siblingsName += `${item.siblingName} ${
        item.siblingOccupation ? '(' + item.siblingOccupation + ')' : ''
      }`
      if (index !== siblings.length - 1) {
        siblingsName += ', '
      }
    })
  }
  return siblingsName || NoSiblings
}

export const getSortedAcademicHistory = (academicHistory = []) => {
  let sortedEducation = []
  if (!_.isEmpty(academicHistory)) {
    sortedEducation = [...academicHistory].sort((a, b) => {
      return new Date(b.startingYear) - new Date(a.startingYear)
    })
  }
  return sortedEducation
}

export const GOOGLE_RECAPTCHA_SITE_KEY =
  '6LeyikEmAAAAAH5_j77NRwgSRG5_x1M1aPgW4xMp'
