import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import Input from 'components/Input'
import React, { useState, useEffect, useRef } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import axios from 'axios'
import AutoComplete from 'components/AutoComplete'
import UploadFiles from 'components/Upload'
import { FileUploader } from 'react-drag-drop-files'
import UploadJobImage from 'assets/icons/uploadJobImage'
import CustomImageReplacer from 'components/CustomImageReplacer'
import { jobTypes } from 'utils'
import _ from 'lodash'
import { ShowToast } from 'utils/toastMessages'
import CustomModal from 'components/CustomModal'
import { CircularProgress } from '@mui/material'
import CropUserImage from 'components/Student/Dashboard/Crop'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from 'assets/icons/delete'
import { translation } from 'utils/germanTranslation'
import useGetBack from 'hooks/useGetBack'
import { ROUTES } from 'utils/routes'
import { useSelector } from 'react-redux'
import { user } from 'store/reducers/user'

const imagesToUpload = 3

const salariesInitialValue = { amount: '', from: '', to: '' }

const imageConfigInitialValue = { toReplace: false, index: null }

const AddJob = ({ title, jobDetail }) => {
  const previewCanvasRef = useRef(null)
  const navigate = useNavigate()
  const { id: userId } = useSelector(user)

  const cropInitialValues = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  }

  const initialValues = {
    id: '',
    images: [],
    title: '',
    type: null,
    duration: null,
    school: '',
    salaries: [],
    category: null,
    aboutJob: '',
    infoLink: '',
    video: {},
    advantages: '',
    disadvantages: '',
    todo: '',
    tobring: '',
    showCropModal: false,
  }

  const [value, setValue] = useState(initialValues)
  const [updatingJob, setUpdatingJob] = useState(false)
  const [showCropModal, setShowCropModal] = useState(false)
  const [crop, setCrop] = useState()
  const [imgSrc, setImgSrc] = useState('')
  const [loadingImage, setLoadingImage] = useState(false)
  const [imageConfig, setImageConfig] = useState(imageConfigInitialValue)

  const handleChange = (newValue, field) => {
    setValue({
      ...value,
      [field]: field === 'type' ? newValue : newValue || '',
    })
  }

  const handleSubmit = async (toUpdateImages = false, newValue = null) => {
    setUpdatingJob(true)
    try {
      const { data } = await axios.put(
        BACKEND_URL + API_URL.job.updateById + value?.id,
        newValue ? { ...newValue } : { ...value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      if (data.success) {
        setUpdatingJob(false)
        ShowToast({ type: 'success', message: data?.message })
        navigate(ROUTES({ userId }).admin.job.allJobs)
      }
    } catch (error) {
      setUpdatingJob(false)
      ShowToast({ type: 'error', message: error })
    }
  }

  const remainingImages = () => {
    const images = value?.images?.filter((image) => image?.title)
    return Number(imagesToUpload - images?.length)
  }

  const handleUploadJobImage = async () => {
    const dataUrl = previewCanvasRef.current.toDataURL('image/jpeg')
    const blob = await fetch(dataUrl).then((r) => r.blob())
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
    const formData = new FormData()
    formData.append('job', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        toReplace: imageConfig?.toReplace,
        imageIndex: imageConfig?.index,
        jobId: value?.id || null,
      },
    }
    setShowCropModal(false)
    const { data } = await axios.post(
      BACKEND_URL + API_URL.job.uploadImage,
      formData,
      config
    )
    const job = await getJobDetails(data?.job?.id || value?.id)
    if (data.success) {
      setValue({
        ...value,
        images: job.job.images,
        id: value?.id || data?.job?.id,
        showCropModal: false,
      })
      setCrop(cropInitialValues)
      setImgSrc('')
    }
  }

  const getJobDetails = async (id) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
    const { data } = await axios.get(
      BACKEND_URL + API_URL.job.findById + id,
      config
    )
    return data
  }

  useEffect(() => {
    if (jobDetail?.id) {
      setValue({
        ...jobDetail,
      })
    }
  }, [jobDetail])

  const handleDurationChange = (newValue) => {
    const getNumber = Number(newValue?.match(/(\d+)/)[0]) || 0
    const salaries = []
    if (getNumber > 0) {
      for (let i = 0; i < getNumber; i++) {
        salaries.push({
          ...salariesInitialValue,
          amount: `${i + 1}. Jahr`,
        })
      }
    }
    setValue({ ...value, duration: newValue, salaries })
  }

  const handleVideoUpload = (file) => {
    const formData = new FormData()
    formData.append('job-video', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        id: value?.id || null,
      },
    }
    axios
      .post(BACKEND_URL + API_URL.job.uploadVideo, formData, config)
      .then(({ data }) => {
        setValue({
          ...value,
          video: data?.video,
          id: data.newJob ? data.data.id : value?.id,
        })
      })
      .catch((error) => {
        ShowToast({ message: error })
      })
  }

  const handleVideoDelete = async () => {
    try {
      const response = await axios.delete(
        BACKEND_URL + API_URL.job.deleteVideo + value?.id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      ShowToast({ type: 'success', message: response?.data?.message })
      handleChange({}, 'video')
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleImageSelect = (file, index, toReplace) => {
    setImageConfig({
      toReplace,
      index,
    })
    setLoadingImage(true)
    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '')
      setLoadingImage(false)
    })
    setShowCropModal(true)
    reader.readAsDataURL(file)
  }

  const deleteImage = async (index) => {
    // Remove image from state by index
    const images = value?.images?.filter((image, i) => i !== index)
    let temp = {
      ...value,
      images,
    }
    // Remove image from server
    handleSubmit(true, temp)
    setValue(temp)
  }

  const onBackButtonEvent = useGetBack(value)

  return (
    <div>
      <EditSkills
        pageTitle={title}
        backLink='/student/dashboard'
        onUpdateClick={handleSubmit}
        cancelButtonTitle={translation.back.toLowerCase()}
        disabled={updatingJob}
      >
        <SimpleEditWrapper
          showAddIcon={false}
          customized={true}
          pageTitle={title}
        >
          <CustomModal
            open={showCropModal}
            handleClose={() => setShowCropModal((prev) => !prev)}
            containerClasses='w-full xs:w-auto flex items-center flex-col gap-3 justify-center'
          >
            {loadingImage ? (
              <CircularProgress />
            ) : (
              <>
                <h1 className='text-lg font-medium w-full'>Bild zuschneiden</h1>
                <CropUserImage
                  imgSrc={imgSrc}
                  aspect={6 / 5}
                  setImgSrc={setImgSrc}
                  crop={crop}
                  setCrop={setCrop}
                  handleUploadUserImage={handleUploadJobImage}
                  previewCanvasRef={previewCanvasRef}
                  closeCropModal={() => setShowCropModal(false)}
                />
              </>
            )}
          </CustomModal>
          <div className='flex items-center flex-wrap gap-4'>
            {value?.images?.map((image, index) => (
              <div key={index} className='relative'>
                <img
                  src={BACKEND_URL + '/' + image?.path}
                  alt='job'
                  className='w-[167px] h-[135px] object-contain rounded-2xl'
                />
                <CustomImageReplacer
                  containerClasses='-right-[10px] -top-[10px]'
                  handleChange={(file) => handleImageSelect(file, index, true)}
                  types={['jpeg', 'png', 'jpg']}
                />
                <div
                  onClick={() => deleteImage(index)}
                  className='-left-[8px] -top-[10px] border-2 border-[#E8E8E8] rounded-full absolute bg-white'
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
            {remainingImages() !== 0 && (
              <FileUploader
                handleChange={(file) =>
                  handleImageSelect(file, value?.images?.length, false)
                }
                types={['jpeg', 'png', 'jpg']}
              >
                <div className='cursor-pointer w-[167px] h-[135px] bg-[#F0F8FF] border border-[#209ADA] border-dashed rounded-lg text-[#458EFF] text-sm font-medium flex items-center justify-center flex-col gap-1'>
                  <UploadJobImage />
                  <p>Bild hochladen</p>
                  <p className='text-center'>
                    Fügen Sie {remainingImages()} weitere Bilder hinzu
                  </p>
                </div>
              </FileUploader>
            )}
          </div>
          <div className='grid grid-cols-none sm:grid-cols-3 gap-6 items-center '>
            <Input
              label={translation.title}
              value={value?.title}
              handleChange={(newValue) => handleChange(newValue, 'title')}
            />
            <AutoComplete
              label={translation.type}
              value={value?.type || null}
              options={['EBA', 'EFZ']}
              handleChange={(newValue) => handleChange(newValue, 'type')}
            />
            <AutoComplete
              label='Dauer'
              value={value?.duration || null}
              options={['2 Jahre', '3 Jahre', '4 Jahre']}
              handleChange={(newValue) => handleDurationChange(newValue)}
            />
            <div className='col-span-full'>
              <Input
                label={translation.schoolName}
                type='email'
                value={value?.school}
                handleChange={(newValue) => handleChange(newValue, 'school')}
              />
            </div>
            <div className='col-span-full'>
              <Input
                label='Beschreibung'
                value={value?.aboutJob}
                handleChange={(newValue) => handleChange(newValue, 'aboutJob')}
              />
            </div>
            <div className='col-span-full'>
              <Input
                label='Link'
                value={value?.infoLink}
                handleChange={(newValue) => handleChange(newValue, 'infoLink')}
              />
            </div>
            {value?.salaries?.map((salary, index) => (
              <div
                key={index}
                className='grid grid-cols-0 sm:grid-cols-3 gap-6 col-span-full'
              >
                <Input
                  label='Gehalt'
                  defaultValue={salary?.amount}
                  handleChange={(newValue) =>
                    setValue({
                      ...value,
                      salaries: value.salaries.map((salary, i) =>
                        i === index ? { ...salary, amount: newValue } : salary
                      ),
                    })
                  }
                  readOnly
                />
                <Input
                  label={translation.from}
                  value={salary?.from}
                  handleChange={(newValue) =>
                    setValue({
                      ...value,
                      salaries: value.salaries.map((salary, i) =>
                        i === index ? { ...salary, from: newValue } : salary
                      ),
                    })
                  }
                />
                <Input
                  label={translation.to}
                  value={salary?.to}
                  handleChange={(newValue) =>
                    setValue({
                      ...value,
                      salaries: value.salaries.map((salary, i) =>
                        i === index ? { ...salary, to: newValue } : salary
                      ),
                    })
                  }
                />
              </div>
            ))}
            <div className='col-span-full grid sm:grid-cols-2 items-center gap-6'>
              <AutoComplete
                label='Stellenkategorie'
                value={value?.category || null}
                options={jobTypes}
                containerClasses='w-auto'
                handleChange={(newValue) => handleChange(newValue, 'category')}
              />
              <UploadFiles
                label='Über Job'
                file={_.isEmpty(value?.video) ? null : value?.video}
                handleFileUpload={(file) => handleVideoUpload(file)}
                onDeleteFile={() => handleVideoDelete()}
                types={[
                  'mp4',
                  'mkv',
                  'webm',
                  'avi',
                  'mov',
                  'flv',
                  'wmv',
                  '3gp',
                  'mpeg',
                ]}
              />
            </div>
            <div className='col-span-full grid sm:grid-cols-2 items-center gap-6'>
              <Input
                label='Die positive Seite des Jobs'
                value={value?.advantages}
                multiline
                minRows={3}
                maxRows={5}
                handleChange={(newValue) =>
                  handleChange(newValue, 'advantages')
                }
              />
              <Input
                label='Schattenseiten des Jobs'
                value={value?.disadvantages}
                multiline
                minRows={3}
                maxRows={5}
                handleChange={(newValue) =>
                  handleChange(newValue, 'disadvantages')
                }
              />
            </div>
            <div className='col-span-full grid sm:grid-cols-2 items-center gap-6'>
              <Input
                label='Das macht man als Einzelhandelsassistent'
                value={value?.todo}
                multiline
                minRows={3}
                maxRows={5}
                handleChange={(newValue) => handleChange(newValue, 'todo')}
              />
              <Input
                label='Das musst du mitbringen'
                value={value?.tobring}
                multiline
                minRows={3}
                maxRows={5}
                handleChange={(newValue) => handleChange(newValue, 'tobring')}
              />
            </div>
          </div>
        </SimpleEditWrapper>
      </EditSkills>
    </div>
  )
}
export default AddJob
