import Navbar from 'components/Navbar/Navbar';
import React from 'react';

const Impressum = () => {
  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen p-6 w-full flex gap-4 items-center justify-center text-softBlack'>
        <div className='w-full max-w-2xl font-bold text-2xl text-softBlack underline'>
          Impressum
        </div>
        <div className='bg-white rounded-lg max-w-2xl p-4 min-w-full md:min-w-[42rem]'>
          <p>Lehrstell.ch</p>
          <br />
          <p>Langensandstr.</p>
          <p>6005 Luzern</p>
          <p>&nbsp;</p>
          <span className='font-bold'>
            Kontakt:{' '}
            <a
              href='mailto:info@lehrstell.ch'
              className='text-blue-500 underline font-medium'
            >
              info@lehrstell.ch
            </a>
          </span>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          {['F. Duss ', 'L. Gilli', 'R. Tominec'].map((name) => (
            <p key={name}>{name}</p>
          ))}
        </div>
      </div>
    </>
  );
};

export default Impressum;
