import CookieIcon from 'assets/icons/cookie'
import Button from 'components/Button'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { translation } from 'utils/germanTranslation'
import { ROUTES } from 'utils/routes'

const CookiePopup = () => {
  const [showAcceptCookies, setShowAcceptCookies] = useState(false)

  useEffect(() => {
    const visited = localStorage.getItem('visited')
    if (visited) {
      setShowAcceptCookies(true)
    }
  }, [])

  return (
    <>
      {!showAcceptCookies ? (
        <div className='w-full max-w-full md:max-w-[500px] bg-white rounded-md shadow-md mt-6 p-4 text-softBlack'>
          <div className='flex items-center gap-4 pb-4'>
            <CookieIcon />
            <h2 className='font-semibold text-lg'>Cookie Consent</h2>
          </div>
          <p>
            Wir verwenden Cookies für Analytik, zur Unterstützung der
            Benutzerfreundlichkeit und personalisierten Inhalt. Klicke auf
            Cookies akzeptieren. Damit stimmst du der Cookie-Nutzung zu.
          </p>
          <Link to={ROUTES().termsOfUse} className='text-blue-500'>
            Unsere Datenschutzerklärung
          </Link>
          <Button
            title={translation.accept}
            onClick={() => {
              localStorage.setItem('visited', true)
              setShowAcceptCookies(true)
            }}
            containerClasses='bg-[#458EFF] text-white rounded-full h-[42px] m-auto w-full mt-4'
            titleClasses='text-center text-base'
          />
        </div>
      ) : null}
    </>
  )
}

export default CookiePopup
