import * as React from 'react';

const DollarIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity={0.5} fill='#05060E'>
      <path d='M11.167 15.107H9.075c-1.367 0-2.475-1.15-2.475-2.567a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625c0 .725.55 1.317 1.225 1.317h2.092c.542 0 .992-.484.992-1.067 0-.725-.259-.867-.684-1.017L8.117 10.59c-.65-.225-1.525-.708-1.525-2.2 0-1.283 1.008-2.317 2.241-2.317h2.092c1.367 0 2.475 1.15 2.475 2.567a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625c0-.725-.55-1.317-1.225-1.317H8.833c-.541 0-.991.484-.991 1.067 0 .725.258.867.683 1.017l3.358 1.183c.65.225 1.526.708 1.526 2.2-.009 1.275-1.009 2.317-2.242 2.317Z' />
      <path d='M10 16.215a.63.63 0 0 1-.625-.625v-10A.63.63 0 0 1 10 4.965a.63.63 0 0 1 .625.625v10a.63.63 0 0 1-.625.625Z' />
      <path d='M10 19.548c-4.942 0-8.959-4.017-8.959-8.958 0-4.942 4.017-8.959 8.958-8.959 4.942 0 8.959 4.017 8.959 8.959 0 4.941-4.017 8.958-8.959 8.958Zm0-16.667c-4.25 0-7.709 3.459-7.709 7.709s3.458 7.708 7.708 7.708 7.709-3.458 7.709-7.708S14.249 2.88 9.999 2.88Z' />
    </g>
  </svg>
);

export default DollarIcon;
