import React, { useState } from 'react'
import { Box } from '@mui/material'
import StudentDetails from './StudentDetails'
import PersonalDetails from './PersonalDetails'
import VerifyEditAccess from 'components/VerifyEditAccess'
import useGetStudentPersonalData from 'hooks/useGetStudentPersonalData'
import Navbar from 'components/Navbar/Navbar'

const StudentDashboard = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit, navigate, isClassTeacher) => (
        <VerifiedStudentDashboard
          id={id}
          canEdit={canEdit}
          navigate={navigate}
          isClassTeacher={isClassTeacher}
        />
      )}
    </VerifyEditAccess>
  )
}

const VerifiedStudentDashboard = ({
  id,
  canEdit,
  navigate,
  isClassTeacher,
}) => {
  const [toggleProfile, setToggleProfile] = useState(false)
  const { personalData, personalDataLoading } = useGetStudentPersonalData(id)

  return (
    <>
      <Navbar />
      <div className='flex justify-center w-full h-full'>
        <Box className='flex max-w-[1760px] px-4 2xl:px-8 w-full gap-4 relative'>
          <StudentDetails
            id={id}
            canEdit={canEdit}
            setToggleProfile={setToggleProfile}
            personalData={personalData}
            isClassTeacher={isClassTeacher}
            navigate={navigate}
          />
          <PersonalDetails
            id={id}
            canEdit={canEdit}
            toggleProfile={toggleProfile}
            setToggleProfile={setToggleProfile}
            personalData={personalData}
            loading={personalDataLoading}
          />
        </Box>
      </div>
    </>
  )
}

export default StudentDashboard
