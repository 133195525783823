import React, { useState, useRef } from 'react'

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )

const CropUserImage = ({
  imgSrc,
  crop,
  setCrop,
  handleUploadUserImage,
  previewCanvasRef,
  aspect = 1 / 1,
  closeCropModal = () => {},
}) => {
  const imgRef = useRef(null)

  const [completedCrop, setCompletedCrop] = useState()

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  return (
    <div className='flex items-center justify-center flex-col gap-4'>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt='Crop me'
            src={imgSrc}
            style={{
              maxHeight: '384px',
              margin: '0 auto',
              width: '100%',
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: 'none',
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
      <div className='flex items-center justify-end w-full gap-4'>
        <Button
          title={translation.cancel}
          containerClasses='py-4 px-6 rounded-md w-full xs:w-auto'
          backgroundColor='#E9F1FC'
          titleClasses='text-[#458EFF] font-semibold '
          onClick={() => {
            closeCropModal()
          }}
        />
        <Button
          title={translation.submit}
          containerClasses='py-4 px-6 rounded-md w-full xs:w-auto'
          backgroundColor='#458EFF'
          titleClasses='text-white font-semibold '
          onClick={() => {
            handleUploadUserImage()
          }}
        />
      </div>
    </div>
  )
}

export default CropUserImage
