import { Box, Checkbox, FormControlLabel, FormGroup, Link } from '@mui/material'
import React, { useState, useEffect } from 'react'
import lehrstellLogo from 'assets/images/logo-transparent.png'
import InputComponent from 'components/Input'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import { performValidation } from './performUserValidation'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { user as userFromState } from 'store/reducers/user'
import { useSelector } from 'react-redux'
import { ROUTES } from 'utils/routes'
import CustomModal from 'components/CustomModal'
import ReCAPTCHA from 'react-google-recaptcha'
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'utils'
import { translation } from 'utils/germanTranslation'

const USER_ROLE_TRANSLATIONS = {
  STUDENT: {
    BUTTON_TEXT: 'Als Lehrperson registrieren',
    TITLE: 'Schüler*In Account erstellen',
  },
  TEACHER: {
    BUTTON_TEXT: 'Als Schüler*In registrieren',
    TITLE: 'Lehrperson Account erstellen',
  },
}
const Register = () => {
  const navigate = useNavigate()
  const loggedInUser = useSelector(userFromState)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const [isConditionsAccepted, setIsConditionsAccepted] = useState(false)
  const [user, setUser] = useState({
    role: translation.student,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const registerUser = async () => {
    let response
    try {
      response = await fetch(BACKEND_URL + API_URL.register, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...user,
          role: user.role === translation.student ? 'Student' : 'Teacher',
        }),
      })
      const data = await response.json()
      return data
    } catch (error) {
      return { error: 'Network Error' }
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault()
    setLoading(true)
    let registration = await registerUser()
    setLoading(false)
    const toastType = registration.error ? 'error' : 'success'
    const toastMessage = registration.error || 'User registered successfully'
    if (toastType === 'error') {
      toast[toastType](toastMessage, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        style: {
          fontSize: '1.1rem',
          fontWeight: 'bold',
          color: '#fff',
          backgroundColor: toastType === 'success' ? '#2e7d32' : '#c62828',
        },
      })
    } else {
      setShowModal(true)
    }
  }

  const [confirmPassword, setConfirmPassword] = useState('')

  const handleChange = (newuser, field) =>
    setUser({ ...user, [field]: newuser })

  useEffect(() => {
    if (loggedInUser.id) {
      const userRole = loggedInUser?.role?.toLowerCase()
      navigate(
        userRole === 'student'
          ? ROUTES({ userId: loggedInUser.id }).student.options
          : userRole === 'teacher'
          ? ROUTES({ userId: loggedInUser.id }).teacher.classes.all
          : userRole === 'admin'
          ? ROUTES({ userId: loggedInUser.id }).admin.dashboard
          : null
      )
    }
  }, [loggedInUser, navigate])

  const onChange = (value) => {
    if (value) setIsCaptchaVerified(true)
  }

  return (
    <>
      <Box
        className='p-4 xs:p-8 bg-white rounded-md max-w-[565px] w-full'
        style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.07)' }}
      >
        <div className='flex justify-end items-end w-full'>
          <Button
            title={
              user.role === translation.student
                ? USER_ROLE_TRANSLATIONS.STUDENT.BUTTON_TEXT
                : USER_ROLE_TRANSLATIONS.TEACHER.BUTTON_TEXT
            }
            onClick={() =>
              handleChange(
                user.role === translation.student
                  ? translation.teacher
                  : translation.student,
                'role'
              )
            }
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] w-[200px]'
            titleClasses={`text-center text-sm`}
          />
        </div>

        <div className='py-6 flex items-center justify-center'>
          <img src={lehrstellLogo} alt='logo' className='w-[10rem]' />
        </div>

        <div className='text-softBlack font-extrabold text-2xl xs:text-3xl text-center pt-4 pb-8'>
          {user.role === translation.student
            ? USER_ROLE_TRANSLATIONS.STUDENT.TITLE
            : USER_ROLE_TRANSLATIONS.TEACHER.TITLE}
        </div>
        <form
          className='flex flex-col gap-6 items justify-center w-full xs:w-[80%] m-auto'
          onSubmit={handleRegister}
        >
          <InputComponent
            placeholder={translation.firstName}
            value={user.firstName}
            labelClasses='font-bold text-black'
            handleChange={(newValue) => handleChange(newValue, 'firstName')}
            required
          />
          <InputComponent
            placeholder={translation.lastName}
            value={user.lastName}
            labelClasses='font-bold text-black'
            handleChange={(newValue) => handleChange(newValue, 'lastName')}
            required
          />
          <InputComponent
            placeholder={translation.email}
            type='email'
            labelClasses='font-bold text-black'
            value={user.email}
            handleChange={(newValue) => handleChange(newValue, 'email')}
            required
          />
          <InputComponent
            placeholder={translation.password}
            type='password'
            labelClasses='font-bold text-black'
            value={user.password}
            handleChange={(newValue) => handleChange(newValue, 'password')}
            required
          />
          <InputComponent
            placeholder={translation.confirmPassword}
            type='password'
            labelClasses='font-bold text-black'
            value={confirmPassword}
            handleChange={(value) => setConfirmPassword(value)}
            error={confirmPassword !== '' && user.password !== confirmPassword}
            {...(confirmPassword !== '' &&
              user.password !== confirmPassword && {
                helperText: `${translation.passwordMismatch}`,
              })}
            required
          />
          <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChange} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <div className=''>
                  Akzeptiere die{' '}
                  <Link component={RouterLink} to='/terms-of-use'>
                    Nutzungsbedingungen
                  </Link>
                </div>
              }
              checked={isConditionsAccepted}
              onChange={() => setIsConditionsAccepted(!isConditionsAccepted)}
            />
          </FormGroup>
          <Button
            title={translation.register}
            type='submit'
            disabled={
              !performValidation({ ...user, confirmPassword }) ||
              loading ||
              confirmPassword !== user.password ||
              !isCaptchaVerified ||
              !isConditionsAccepted
            }
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] w-full'
          />
          <div className='text-center text-sm border border-gray-500 p-2 rounded m-auto'>
            Sie haben bereits ein Konto?{' '}
            <Link component={RouterLink} to='/login' underline='hover'>
              Hier anmelden
            </Link>
          </div>
        </form>
      </Box>
      <CustomModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        containerClasses='w-[90%] max-w-[565px]'
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <div className='text-softBlack font-bold text-2xl'>
            Registrierung erfolgreich!
          </div>
          <div className='text-center text-softBlack'>
            Wir haben eine E-Mail mit einem Bestätigungslink zur Registrierung
            deines Profils an deine E-Mail-Adresse gesendet. Bitte klicke auf
            den Bestätigungslink, um dein Profil zu aktivieren.
          </div>
          <Button
            title='OK'
            onClick={() => navigate('/login')}
            type='button'
            containerClasses='bg-[#458EFF] text-white w-full rounded-md h-[40px]'
          />
        </div>
      </CustomModal>
    </>
  )
}

export default Register
