import React from 'react'
import JobImage from 'assets/images/JobImage.png'
import { Link, useNavigate } from 'react-router-dom'
import { BACKEND_URL } from 'utils/apiConstants'
import { ROUTES } from 'utils/routes'
import { useState } from 'react'
import { useEffect } from 'react'
import { jobCategories } from 'utils'
import NothingToShow from '../NothingToShow'
import { Tooltip } from '@mui/material'

const CategoryJobs = ({ jobs, jobCategory, jobType }) => {
  const [value, setValue] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    // debugger
    if (!jobType && !jobCategory) {
      navigate(ROUTES().student.job.all)
    }
    setValue([])
    if (jobType !== null) {
      const temp =
        jobCategory !== null && jobCategory !== jobCategories[0]
          ? jobs[jobType]?.jobs?.filter((job) => job.type === jobCategory)
          : jobs[jobType]?.jobs
      setValue(temp)
    } else if (jobCategory !== null) {
      let temp = []
      if (jobCategory === jobCategories[0]) {
        for (const type in jobs) {
          const specificTypeJobs = jobs[type]?.jobs
          if (specificTypeJobs.length) {
            temp = [...temp, ...specificTypeJobs]
          }
        }
        setValue(temp)
        return
      }
      for (const type in jobs) {
        const specificTypeJobs = jobs[type]?.jobs
        if (specificTypeJobs.length) {
          temp = [
            ...temp,
            ...specificTypeJobs?.filter((job) => job.type === jobCategory),
          ]
        }
      }
      setValue(temp)
    }
  }, [jobCategory, jobType, jobs])

  return (
    <div>
      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-center sm:justify-between w-full'>
        <h1 className='py-6 font-bold text-xl'>
          Übersicht{' '}
          {[jobType, jobCategory]
            ?.filter((item) => Boolean(item) && item !== jobCategories[0])
            .join(' ')}{' '}
          Berufe
        </h1>
      </div>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
        {/* 
            <Link key={index} to={ROUTES({ jobId: job.id }).student.job.jobId}>
              <div
                className='bg-white rounded-2xl cursor-pointer hover:scale-[1.04] transition-all ease-in-out'
                style={{ boxShadow: '0px 9px 44px rgba(17, 37, 66, 0.08)' }}
              >
                <div className='h-[189px] rounded-t-2xl'>
                  <img
                    src={
                      job?.images[0]
                        ? BACKEND_URL + '/' + job?.images[0]?.path
                        : JobImage
                    }
                    alt='Job'
                    className='w-full h-full object-contain rounded-t-2xl'
                  />
                </div>
                <div className='p-4'>
                  <h1 className='font-semibold truncate'>
                    {'Industrie- und Unterlagsbodenpraktiker*in EBA' ||
                      job.title}
                  </h1>
                </div>
              </div>
            </Link> */}
        {value?.length > 0 ? (
          [...value]
            .sort((a, b) => a.title.localeCompare(b.title))
            ?.map((job, index) => (
              <Link
                key={index}
                to={ROUTES({ jobId: job.id }).student.job.jobId}
              >
                {/* <Tooltip title={'dasdad dasd'} placement='top'>
                <div className='border w-full h-56 p-4 bg-white hover:bg-gray-100 rounded-2xl hover:scale-[1.04] transition-all ease-in-out'>
                  <img
                    src={
                      job?.images[0]
                        ? BACKEND_URL + '/' + job?.images[0]?.path
                        : JobImage
                    }
                    alt='this'
                    className='w-full h-32 object-cover mb-2'
                  />
                  <p className='truncate text-lg font-semibold'>
                    {'dasdad dasddasdad dasddasdad dasddasdad dasddasdad dasd'}
                  </p>
                </div>
              </Tooltip> */}
                <Tooltip title={job.title} placement='top'>
                  <div className='shadow-sm border w-full h-56 p-4 hover:bg-gray-100 rounded-2xl hover:scale-[1.04] transition-all ease-in-out'>
                    <img
                      src={
                        job?.images[0]
                          ? BACKEND_URL + '/' + job?.images[0]?.path
                          : JobImage
                      }
                      alt='this'
                      className='w-full h-32 object-cover mb-2'
                    />
                    <p className='h-12 overflow-hidden overflow-ellipsis'>
                      {job.title}
                    </p>
                  </div>
                </Tooltip>
              </Link>
            ))
        ) : (
          <div className='col-span-full'>
            <NothingToShow title='Bisher noch keine Berufe hinzugefügt' />
          </div>
        )}
      </div>
    </div>
  )
}

export default CategoryJobs
