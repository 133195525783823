import React, { useCallback, useEffect, useState } from 'react'
import TrophyIcon from 'assets/icons/trophy'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import useGetUserDocuments from 'hooks/useGetUserDocuments'
import {
  CERTIFICATE,
  CV,
  INTERLOCKING_TEST,
  WORK_TRIAL,
} from 'utils/documentTypes'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { BACKEND_URL } from 'utils/apiConstants'
import { CancelOutlined, ZoomIn, ZoomOut } from '@mui/icons-material'
import { CircularProgress, Skeleton } from '@mui/material'
import { ROUTES } from 'utils/routes'
import _ from 'lodash'
import empty from 'assets/images/empty.png'
import EditableTag from './EditableTag'
import InterlockingTestIcon from 'assets/icons/interlockingTest'
import WorkTrailIcon from 'assets/icons/workTrail'
import { PDFDownloadLink } from '@react-pdf/renderer'
import CVGenerator from 'components/CVGenerator'
import GenerateWordCV from './ShareProfileModal/GenerateWordCV'
import useGetReferences from 'hooks/useGetReferences'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { translation } from 'utils/germanTranslation'
import DownloadCvIcon from 'assets/icons/lebensauf'

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 440,
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
}

const Documents = ({
  userId,
  canEdit,
  setDocuments,
  languages,
  hobbies,
  academicHistory,
  trial,
  personalData,
  profilePicture,
}) => {
  const navigate = useNavigate()
  const currentPdfInitialState = {
    link: '',
    totalPages: 0,
  }
  const [open, setOpen] = useState(false)
  const [scale, setScale] = useState(1)
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [currentPdf, setCurrentPdf] = useState(currentPdfInitialState)
  const [modelDetails, setModelDetails] = useState({
    title: '',
    data: [],
  })
  const { data: documents } = useGetUserDocuments(userId)

  const { data: referencesData } = useGetReferences(userId)

  const handleOpen = (type) => {
    setOpen(true)
    setModelDetails({
      title: type,
      data:
        type !== CV
          ? documents.filter(
              (document) =>
                document?.type?.toLowerCase() === type?.toLowerCase()
            )
          : null,
    })
  }
  const handleClose = () => {
    setScale(1)
    setOpen(false)
  }
  const handleClosePdf = () => {
    setCurrentPdf(currentPdfInitialState)
    setShowDocumentModal(false)
    setScale(1)
  }

  useEffect(() => {
    if (!_.isEmpty(documents)) {
      setDocuments(documents)
    }
  }, [documents, setDocuments])

  const handleScale = (isZoomIn) => {
    if (isZoomIn) {
      setScale(scale + 0.1)
    } else {
      setScale(scale - 0.1)
    }
  }

  const [image, setImage] = useState({ url: '', loading: true })

  const generateImage = useCallback(async () => {
    const blob = await fetch(`${BACKEND_URL}/${profilePicture}`).then((r) =>
      r.blob()
    )
    setImage({
      url: blob,
      loading: false,
    })
  }, [profilePicture])

  useEffect(() => {
    if (profilePicture) {
      generateImage()
    } else {
      setImage({ url: '', loading: false })
    }
  }, [profilePicture, generateImage])
  const TitleClassNames = 'text-base sm:text-xs 2xl:text-sm capitalize'
  const ContainerCLassNames = 'py-3 px-4 sm:px-3 sm:py-2 justify-center'

  return (
    <div>
      <div className='flex items-center gap-3 flex-wrap'>
        <EditableTag
          canEdit={canEdit}
          icon={<TrophyIcon />}
          title={CERTIFICATE}
          titleClasses={TitleClassNames}
          containerClasses={ContainerCLassNames}
          onClick={() => handleOpen(CERTIFICATE)}
          link={
            ROUTES({ userId }).student.edit.documents + `?type=${CERTIFICATE}`
          }
        />
        <EditableTag
          canEdit={canEdit}
          icon={<InterlockingTestIcon />}
          title={INTERLOCKING_TEST}
          titleClasses={TitleClassNames}
          containerClasses={ContainerCLassNames}
          onClick={() => handleOpen(INTERLOCKING_TEST)}
          link={
            ROUTES({ userId }).student.edit.documents +
            `?type=${INTERLOCKING_TEST}`
          }
        />
        <EditableTag
          canEdit={canEdit}
          icon={<WorkTrailIcon />}
          title={WORK_TRIAL}
          titleClasses={TitleClassNames}
          containerClasses={ContainerCLassNames}
          onClick={() => handleOpen(WORK_TRIAL)}
          link={
            ROUTES({ userId }).student.edit.documents + `?type=${WORK_TRIAL}`
          }
        />
        <EditableTag
          canEdit={false}
          icon={<DownloadCvIcon />}
          title={CV}
          containerClasses={ContainerCLassNames}
          titleClasses={TitleClassNames}
          onClick={() => handleOpen(CV)}
        />
      </div>

      <Modal
        open={showDocumentModal}
        onClose={() => setShowDocumentModal(false)}
      >
        <Box className='max-w-[800px] bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[90%] shadow-md p-2 rounded-md w-full'>
          <div className='overflow-auto h-full m-auto'>
            <Document
              file={BACKEND_URL + '/' + currentPdf.link}
              loading={
                <Skeleton variant='rounded' width={'100%'} height={'100%'} />
              }
              onLoadSuccess={(document) => {
                setCurrentPdf({
                  ...currentPdf,
                  totalPages: document._pdfInfo.numPages,
                })
              }}
            >
              <div className='relative'>
                <div
                  onClick={handleClosePdf}
                  className='fixed top-2 left-4 z-10 cursor-pointer'
                >
                  <CancelOutlined fontSize='large' />
                </div>
                <div className='fixed top-2 flex gap-4 right-8 z-10 '>
                  <button onClick={() => handleScale(true)}>
                    <ZoomIn
                      fontSize='large'
                      className='hover:bg-blue-100 rounded cursor-pointer'
                    />
                  </button>
                  <button onClick={() => handleScale(false)}>
                    <ZoomOut
                      fontSize='large'
                      className='hover:bg-blue-100 rounded cursor-pointer'
                    />
                  </button>
                </div>
                {[...Array(currentPdf.totalPages)]?.map((page, index) => (
                  <Page
                    scale={scale}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    className=''
                  />
                ))}
              </div>
            </Document>
          </div>
        </Box>
      </Modal>

      <Modal open={open} onClose={handleClose}>
        {modelDetails.title !== CV ? (
          <Box sx={style} className='p-2 sm:p-4 rounded-md '>
            <h1 className='text-2xl font-bold mb-4 capitalize'>
              {modelDetails.title === INTERLOCKING_TEST
                ? `${INTERLOCKING_TEST} (z.B. Stellwerk)`
                : modelDetails.title}
            </h1>
            <div className='flex flex-col rounded'>
              {modelDetails.data.length ? (
                modelDetails.data?.map((item, index) => (
                  <ul key={index} className='px-4'>
                    <li
                      className='list-disc list-outside my-1 no-underline hover:underline underline-offset-1 hover:text-blue-700 cursor-pointer transition-all ease-linear'
                      onClick={() => {
                        setShowDocumentModal(true)
                        setCurrentPdf({
                          link: item?.file?.path,
                          totalPages: 0,
                        })
                      }}
                    >
                      {item?.file?.title}
                    </li>
                  </ul>
                ))
              ) : (
                <div className='flex flex-col gap-2 items-center justify-center'>
                  <img src={empty} alt='empty' className='w-20' />
                  <p>leer</p>
                </div>
              )}
            </div>
            <Button
              title={`${
                modelDetails.title === CERTIFICATE
                  ? 'Zeugnis'
                  : capitalize(modelDetails.title)
              } ${translation.add.toLowerCase()}`}
              titleClasses='text-[#458EFF] text-base font-semibold'
              containerClasses='rounded-md w-full h-[50px] bg-[#E9F1FC] mt-2'
              onClick={() =>
                navigate(
                  ROUTES({ userId }).student.edit.documents +
                    `?type=${modelDetails.title}`
                )
              }
            />
          </Box>
        ) : (
          <Box sx={style} className='p-2 sm:p-4 rounded-md '>
            <ul className='px-4'>
              <PDFDownloadLink
                document={
                  <CVGenerator
                    languages={languages}
                    hobbies={hobbies}
                    academicHistory={academicHistory}
                    trial={trial}
                    personalData={personalData}
                    profilePicture={profilePicture}
                  />
                }
                fileName={`${capitalize(CV)}_${personalData?.firstName} ${
                  personalData?.lastName
                }.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <li className='list-disc list-outside my-1 no-underline hover:underline underline-offset-1 hover:text-blue-700 cursor-pointer transition-all ease-linear'>
                        Lebenslauf als PDF herunterladen
                      </li>
                    </>
                  )
                }
              </PDFDownloadLink>
              <GenerateWordCV
                languages={languages.length ? languages : []}
                hobbies={hobbies.length ? hobbies : []}
                academicHistory={academicHistory.length ? academicHistory : []}
                trial={trial.length ? trial : []}
                personalData={personalData}
                profilePicture={image.url}
                referencesData={referencesData.length ? referencesData : []}
                loading={image.loading}
              />
            </ul>
          </Box>
        )}
      </Modal>
    </div>
  )
}

export default Documents
