import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import Button from 'components/Button'
import { getLetterTitle } from 'components/Student/MotivationLetter/RowTemplate'
import React from 'react'
import { translation } from 'utils/germanTranslation'

const SelectLetterScreen = ({
  letters,
  setValue,
  setSelectLetter,
  value,
  user,
}) => {
  return (
    <div className='bg-softWhite p-4'>
      <div>
        Wähle ein Motivationsschreiben aus, welches im Anhang hinzugefügt wird.
      </div>
      {letters.length
        ? letters.map((letter) => (
            <FormGroup key={letter?.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.isMotivationLetterAttached === letter?.id}
                    onChange={() => {
                      setValue({
                        ...value,
                        isMotivationLetterAttached: letter?.id,
                      })
                      setSelectLetter(false)
                    }}
                  />
                }
                label={getLetterTitle(letter, user)}
                className='break-words'
              />
            </FormGroup>
          ))
        : 'There are no motivation letters'}
      <Button
        title={translation.back}
        onClick={() => setSelectLetter(false)}
        containerClasses='bg-blue-200 px-4 py-2 rounded-lg mt-3'
      />
    </div>
  )
}

export default SelectLetterScreen
