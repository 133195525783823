import VerifyEditAccess from 'components/VerifyEditAccess'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { user } from 'store/reducers/user'
import StudentCard from './Card'
import { getStudentOptions } from './studentOptions'

const StudentOptions = () => {
  const loggedInUser = useSelector(user)
  const studentOptions = getStudentOptions(loggedInUser.id)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  useEffect(() => {
    if (!loggedInUser.id) {
      navigate('/login')
    } else {
      setLoading(false)
    }
  }, [loggedInUser, navigate])

  return (
    <>
      {!loading && (
        <VerifyEditAccess>
          {(id, canEdit) => (
            <div className='flex items-center bg-softWhite text-softBlack flex-col gap-8 px-4 py-12 min-h-screen'>
              <div className='flex items-center justify-center max-w-[1092px] flex-wrap gap-8'>
                {studentOptions.map(({ title, icon, link }, index) => (
                  <Link to={link} key={index}>
                    <StudentCard title={title} icon={icon} />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </VerifyEditAccess>
      )}
    </>
  )
}

export default StudentOptions
