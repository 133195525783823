import { ArrowUpward } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import React from 'react';

const SortableTableCell = ({
  performSorting,
  onHover,
  title,
  onHoverLeave,
  order,
  isActive,
}) => {
  return (
    <TableCell
      onClick={(...props) => performSorting(props)}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
    >
      <div className='flex items-center gap-4'>
        {title}
        <ArrowUpward
          className={`${order === 'asc' && 'transform rotate-180'} ${
            isActive ? 'opacity-70' : 'opacity-0'
          } w-4`}
        />
      </div>
    </TableCell>
  );
};

export default SortableTableCell;
