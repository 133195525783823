import Navbar from 'components/Navbar/Navbar';
import React from 'react';

const AGB = () => {
  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen p-6 w-full flex gap-4 items-center justify-center text-softBlack'>
        <div className='w-full font-bold text-2xl text-softBlack underline'>
          AGBs
        </div>
        <div className='bg-white rounded-lg p-4'>
          <span className='font-bold'>Allgemeine Bestimmungen</span>
          <br />
          <p>
            Lehrstell.ch behält sich das Recht vor, diese Bedingungen jederzeit
            und ohne Vorankündigung zu aktualisieren oder zu ändern. Nutzer sind
            an die jeweils letzte und aktuelle Fassung gebunden.
          </p>
          <p>&nbsp;</p>
          <span className='font-bold'>Nutzung </span>
          <p>Die Nutzung von Lehrstell.ch ist für alle Nutzer kostenlos.</p>
          <br />
          <span className='font-bold'>
            Datenschutz (Lebenslauf- & Motivationsschreibengenerator)
          </span>
          <p>
            Wenn Nutzer den Lebenslauf oder das Motivationsschreiben für die
            Generierung erstellen, werden die Daten im Webbrowser des Users
            gespeichert. Auf der Webseite hat man die Möglichkeit die Cookies zu
            löschen, wenn der Nutzer auf "Alle Daten löschen" klickt.
          </p>
          <p>Haftungsausschluss</p>
          <p>
            Lehrstell.ch haftet nicht für allfällige Systemfehler und
            Datenverluste.
          </p>
          <br />
          <span className='font-bold'>Copyright</span>
          <p>
            Die nachstehenden Bedingungen sowie andere für das Internet
            bestehenden Gesetze oder Bestimmungen gelten für alle Nutzer der
            Website Lehrstell.ch. Ohne schriftliche Genehmigung von Lehrstell.ch
            dürfen keine Informationen/Bilder und weitere Daten bereitgestellt,
            übertragen oder anderweitig benutzt werden.
          </p>
          <br />
          <span className='font-bold'>Annahmeerklärung</span>
          <p>
            Mit der Nutzung von Lehrstell.ch akzeptiert der Nutzer die AGB`s
            vollumfänglich.
          </p>
          <p>Anwendbares Recht und Gerichtsstand</p>
          <p>
            Auf diesen AGBs ist nur Schweizer Recht anwendbar. Der Gerichtsstand
            befindet sich am Sitz von Lehrstell.ch.
          </p>
        </div>
      </div>
    </>
  );
};

export default AGB;
