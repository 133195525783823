import * as React from 'react';
const CookieIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width={46} {...props}>
    <path
      d='M59 28a8 8 0 0 1-7.948-7.111 7.982 7.982 0 0 1-11.761-9A7.955 7.955 0 0 1 38 12a8 8 0 0 1-8-8 8.126 8.126 0 0 1 .058-.928A29 29 0 1 0 60.7 27.816 8.024 8.024 0 0 1 59 28Z'
      style={{
        fill: '#a65c2e',
      }}
    />
    <path
      d='M60.7 27.816a7.987 7.987 0 0 1-9.643-6.927 7.974 7.974 0 0 1-3.088 1.045A33.5 33.5 0 0 1 14.5 54c-.478 0-.952-.016-1.424-.036A28.99 28.99 0 0 0 60.7 27.816Z'
      style={{
        fill: '#964c1e',
      }}
    />
    <circle
      cx={29}
      cy={28}
      r={4}
      style={{
        fill: '#663318',
      }}
    />
    <circle
      cx={22}
      cy={48}
      r={4}
      style={{
        fill: '#663318',
      }}
    />
    <circle
      cx={44}
      cy={48}
      r={4}
      style={{
        fill: '#663318',
      }}
    />
    <circle
      cx={13}
      cy={32}
      r={2}
      style={{
        fill: '#854219',
      }}
    />
    <circle
      cx={37}
      cy={42}
      r={2}
      style={{
        fill: '#854219',
      }}
    />
    <circle
      cx={48}
      cy={30}
      r={2}
      style={{
        fill: '#854219',
      }}
    />
    <path
      d='m18.584 13.315-1.168-1.623a25.056 25.056 0 0 1 3.125-1.917l.918 1.778a22.979 22.979 0 0 0-2.875 1.762ZM9.994 25.291l-1.914-.582a25 25 0 0 1 7.253-11.343l1.334 1.49a23 23 0 0 0-6.673 10.435Z'
      style={{
        fill: '#c47747',
      }}
    />
    <script />
  </svg>
);
export default CookieIcon;
