import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import ImageGallery from 'react-image-gallery'

import PlayIcon from 'assets/icons/play'
import Tag from 'components/Tag'
import DollarIcon from 'assets/icons/dollar'
import DurationIcon from 'assets/icons/duration'
import SchoolIcon from 'assets/icons/school'
import JobDetailCard from './JobDetailCard'
import SunnySide from 'assets/images/sunny-side.png'
import ShadySide from 'assets/images/shady-side.png'
import useGetJobById from 'hooks/useGetJobById'
import { BACKEND_URL } from 'utils/apiConstants'
import CustomVideoPlayerModal from 'components/CustomVideoPlayerModal'
import Custom404Error from 'components/Custom404Error'
import CustomModal from 'components/CustomModal'
import './index.css'
import Navbar from 'components/Navbar/Navbar'
import { translation } from 'utils/germanTranslation'
import CustomButton from 'components/Button'
import InfoLinkSearchIcon from 'assets/icons/infoLinkSearch'

const JobDetail = () => {
  let { jobId } = useParams()
  const { data: jobDetails, loading, error } = useGetJobById(jobId)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [imageModalDetails, setImageModalDetails] = useState({
    show: false,
    image: '',
  })
  let navigate = useNavigate()
  const isVideoAvailable = _.isEmpty(jobDetails?.video)
  const [jobImages, setJobImages] = useState([])

  useEffect(() => {
    if (jobDetails?.images?.length > 0) {
      const images = jobDetails?.images?.map((image) => ({
        original: `${BACKEND_URL}/${image?.path}`,
        thumbnail: `${BACKEND_URL}/${image?.path}`,
      }))
      setJobImages(images)
    }
  }, [jobDetails])

  return (
    <div className='w-full h-full bg-softWhite'>
      <Navbar />
      {loading ? (
        <div className='flex items-center justify-center min-h-screen'>
          <CircularProgress />
        </div>
      ) : error ? (
        <Custom404Error />
      ) : (
        <Box className='max-w-[1760px] px-6 sm:px-8 w-full text-softBlack pb-6'>
          <div className='py-8'>
            <div
              className='flex items-center gap-[10px] cursor-pointer max-w-min hover:scale-105'
              onClick={() => navigate(-1)}
            >
              <button>
                <ArrowBackIcon />
              </button>
              <p className='font-semibold text-xl'>{translation.back}</p>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col md:flex-row gap-8'>
              <div className='w-full sm:w-[260px] h-[300px]'>
                <ImageGallery
                  items={jobImages}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  onClick={(e) =>
                    setImageModalDetails({ show: true, image: e.target.src })
                  }
                />
              </div>
              <div className='flex flex-col gap-[18px] w-full max-w-[933px]'>
                <div className='flex flex-col sm:flex-row items-center justify-center md:justify-start gap-2 sm:gap-4'>
                  <p className='text-3xl font-extrabold text-center sm:text-left'>
                    {jobDetails.title}
                  </p>
                  <div className='flex items-center gap-1'>
                    <Tooltip
                      title={
                        isVideoAvailable
                          ? `Es hat noch kein Video zum Lehrberuf`
                          : ''
                      }
                      arrow
                    >
                      <span>
                        <Button
                          onClick={() => setShowVideoModal(true)}
                          disabled={isVideoAvailable}
                          startIcon={<PlayIcon disabled={isVideoAvailable} />}
                        >
                          <p
                            className={
                              isVideoAvailable
                                ? 'text-black opacity-60'
                                : 'text-[#FF0100]'
                            }
                          >
                            Über den Job
                          </p>
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className='grid grid-cols-none gap-4 text-base text-softBlack w-full'>
                  <div>
                    <Tag
                      title={`Dauer: ${jobDetails?.duration}`}
                      icon={<DurationIcon />}
                      style={{ alignItems: 'start' }}
                    />
                  </div>
                  <div>
                    <Tag
                      title={`Schule: ${jobDetails?.school}`}
                      Dauer
                      icon={<SchoolIcon />}
                      style={{ alignItems: 'start' }}
                    />
                  </div>
                  <div>
                    {jobDetails?.salaries?.map((salary, i) => (
                      <div className='flex gap-1' key={i}>
                        <Tag
                          key={i}
                          title={`Gehalt ${salary?.amount}: `}
                          icon={<DollarIcon />}
                        />
                        <p>
                          {salary?.from && salary?.from}
                          {' - '}
                          {salary?.to && salary?.to}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className='flex items-center gap-2'>
                    <InfoLinkSearchIcon />
                    <a
                      href={
                        jobDetails.infoLink
                          ? jobDetails.infoLink.startsWith('http://') ||
                            jobDetails.infoLink.startsWith('https://')
                            ? jobDetails.infoLink
                            : `http://${jobDetails.infoLink}`
                          : '#'
                      }
                      target={jobDetails.infoLink?'_blank':'_self'}
                      rel='noopener noreferrer'
                    >
                      <CustomButton
                        title={
                          'Schnupperlehren & Lehrstellen auf berufsberatung.ch'
                        }
                        titleClasses='text-white text-sm font-semibold text-left'
                        containerClasses='rounded-md w-full max-w-[380px] h-[40px] bg-[#458EFF] px-2'
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='grid grid-cols-none md:grid-cols-2 lg:grid-cols-5 gap-6 '>
              <JobDetailCard
                title={`Das macht man als ${jobDetails.title}`}
                containerClasses='col-span-auto lg:col-span-3 p-4 order-1'
                data={jobDetails?.todo}
              />
              <JobDetailCard
                title='Die positive Seite des Jobs'
                containerClasses='col-span-auto lg:col-span-2 p-4 order-3 sm:order-2'
                icon={SunnySide}
                data={jobDetails.advantages}
              />
              <JobDetailCard
                title='Das musst du mitbringen'
                containerClasses='col-span-auto lg:col-span-3 p-4 order-2 sm:order-3'
                data={jobDetails?.tobring}
              />
              <JobDetailCard
                title='Herausforderungen im Job'
                containerClasses='col-span-auto lg:col-span-2 p-4 order-4'
                icon={ShadySide}
                data={jobDetails.disadvantages}
              />
            </div>
          </div>
          <div
            className='my-6 font-medium text-base'
            dangerouslySetInnerHTML={{ __html: jobDetails?.aboutJob }}
            style={{ whiteSpace: 'pre-line' }}
          ></div>
        </Box>
      )}
      <CustomVideoPlayerModal
        open={showVideoModal}
        handleClose={() => setShowVideoModal(false)}
        videoURL={BACKEND_URL + '/' + jobDetails?.video?.path}
      />
      <CustomModal
        open={imageModalDetails.show}
        handleClose={() => setImageModalDetails({ show: false, image: '' })}
        padding='0'
      >
        <Close
          onClick={() => setImageModalDetails({ show: false, image: '' })}
          sx={{
            position: 'absolute',
            top: '-20px',
            right: '-20px',
            borderRadius: '50%',
            cursor: 'pointer',
            backgroundColor: 'red',
            color: 'white',
            width: '45px',
            height: '45px',
          }}
        />
        <ChevronLeft
          className='absolute top-1/2 -translate-y-1/2 text-white cursor-pointer hover:text-[7rem] transition-all text-8xl left-0'
          fontSize='inherit'
          onClick={() => {
            const index = jobImages.findIndex(
              (image) =>
                image.original.replace(/\\/g, '').replace(/\//g, '').trim() ===
                imageModalDetails.image
                  .replace(/\//g, '')
                  .replace(/\\/g, '')
                  .trim()
            )
            setImageModalDetails({
              show: true,
              image:
                jobImages[index <= 0 ? jobImages.length - 1 : index - 1]
                  .original,
            })
          }}
        />
        <img src={imageModalDetails.image} alt='job' className='w-full' />
        <ChevronRight
          className='absolute top-1/2 -translate-y-1/2 text-white cursor-pointer hover:text-[7rem] transition-all text-8xl right-0'
          fontSize='inherit'
          onClick={() => {
            const index = jobImages.findIndex(
              (image) =>
                image.original.replace(/\\/g, '').replace(/\//g, '').trim() ===
                imageModalDetails.image
                  .replace(/\//g, '')
                  .replace(/\\/g, '')
                  .trim()
            )
            setImageModalDetails({
              show: true,
              image:
                jobImages[index === jobImages.length - 1 ? 0 : index + 1]
                  .original,
            })
          }}
        />
      </CustomModal>
    </div>
  )
}

export default JobDetail
